import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Question, Page, QuestionType } from "../api/generated"
import PercentageIndicator, {
  PercentageIndicatorProps
} from "./charts/PercentageIndicator"
import { roundToOneDecimal } from "../scripts/utils"

interface DimensionListItemProps {
  question?: Question
  pctIndicatorProps: PercentageIndicatorProps
  page: Page
}

const DimensionListItem = ({
  page,
  question,
  pctIndicatorProps
}: DimensionListItemProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleLeave = () => {
    setIsHovered(false)
  }

  const { abstained, amount, approval } = pctIndicatorProps

  const button = question ? (
    <button
      className="text- col-span-10 flex place-items-center  justify-self-start break-words text-left text-2xl hover:underline"
      onClick={() => navigate(`/results/${page.id}/${question.id}`)}
    >
      {t(`wfs:question.${question.id}`)}
    </button>
  ) : (
    <button
      className={`col-span-10  place-items-center justify-self-start ${
        page.questions[0].qType === QuestionType.Text ? "pt-1" : "pb-3"
      }  text-left text-2xl hover:underline`}
      onClick={() => navigate("/results/" + page.id)}
    >
      {t(`wfs:page.${page.id}`)}
    </button>
  )

  return (
    <React.Fragment key={page.id}>
      <div
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
        className={`col-span-2 place-items-center justify-self-center `}
      >
        <PercentageIndicator {...pctIndicatorProps} />
      </div>
      {!isHovered ? (
        button
      ) : (
        <div className=" col-span-10  place-items-center justify-self-start">
          <div className="flex h-full w-full flex-col justify-center  text-sm sm:flex-row sm:items-center sm:gap-4 sm:text-xl">
            {approval && (
              <span className="flex gap-2">
                <span className="flex font-bold">
                  {t("pctIndicator.approval")}:
                </span>{" "}
                {roundToOneDecimal(approval)}%
              </span>
            )}

            <span className="flex gap-2">
              <span className="flex font-bold">
                {t("pctIndicator.amount")}:
              </span>
              {amount}
            </span>

            <span className="flex gap-2">
              <span className="flex font-bold">
                {t("pctIndicator.abstained")}:
              </span>
              {abstained}
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default DimensionListItem
