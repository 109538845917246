import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Employee } from "../../api/generated"
import { TreeData } from "../../components/TreeSelect"
import {
  convertToTreeData,
  getAllSubordinates,
  getManagerTree
} from "../../scripts/utils"

export interface SelectedManager {
  tree: TreeData //whole manager tree, but expanded till selected Manager
  name: string
  id: string
  subManagers: string[]
}

export interface OrganizationStore {
  root: TreeData //only managers included, root node = user or impersonation
  selectedManager?: SelectedManager //manager tree, but selected Manager has checked: true
  showIndirect: boolean //if true,also show results of indirect employees
  subManagers: string[] //of root user or impersonation
}

const initialState: OrganizationStore = {
  root: {} as TreeData,
  showIndirect: true,
  subManagers: []
}

const organziationSlice = createSlice({
  name: "organzitaion",
  initialState,
  reducers: {
    setOrganizationState: (state, action: PayloadAction<Employee>) => {
      const treeData = convertToTreeData(getManagerTree(action.payload))
      state.root = treeData
      state.subManagers = getAllSubordinates(treeData).map(
        subordinate => subordinate.id
      )
    },
    setSelectedManager: (
      state,
      action: PayloadAction<SelectedManager | undefined>
    ) => {
      state.selectedManager = action.payload
    },
    toggleTeamView: state => {
      state.showIndirect = !state.showIndirect
    }
  }
})

export const { setOrganizationState, setSelectedManager, toggleTeamView } =
  organziationSlice.actions

export default organziationSlice.reducer
