import { ApolloQueryResult } from "@apollo/client"
import { format } from "date-fns"
import React, { useEffect, useMemo, useState } from "react"
import { Exact, GetSurveyAndResultsQuery } from "../api/generated"
import { useAppDispatch } from "../app/hooks"
import { setCount, setRange } from "../features/filter/surveySlice"
import { getPeriod } from "../scripts/utils"
import { ReactComponent as Trash } from "../assets/icons/trash.svg"

interface Quarter {
  date: string
  count: number
}

interface YearBtnProps {
  arr: string[]
  i: number
  isNext?: boolean
  onClick: React.Dispatch<React.SetStateAction<number>>
}

const YearBtn = ({ i, arr, isNext, onClick: setPage }: YearBtnProps) => {
  const index = isNext ? i + 1 : i - 1
  const isVisible = (isNext && i < arr.length) || (!isNext && i > 0)

  return (
    <button
      className={`flex w-9  text-exxeta-gray-5 decoration-double decoration-2 underline-offset-4 hover:underline `}
      onClick={() => setPage(index)}
      type="button"
    >
      {isVisible ? arr[index] : ""}
    </button>
  )
}

interface CurrentDateSelectProps {
  date: Date
  onClick: React.Dispatch<React.SetStateAction<boolean>>
}

const CurrentDateSelect = ({
  date,
  onClick: setIsOpen
}: CurrentDateSelectProps) => {
  return (
    <div
      className="flex w-full items-center justify-center rounded-md border-2 border-black"
      id="currentSelection"
    >
      <button
        className="flex items-center justify-center text-center font-semibold"
        type="button"
        onClick={() => setIsOpen(prev => !prev)}
      >
        {getPeriod(date)}
      </button>
    </div>
  )
}

const getCalendar = (
  quartersByYear: Map<string, Quarter[]>,
  count: number,
  onDateSelect: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  onYearSelect: React.Dispatch<React.SetStateAction<number>>
) =>
  [...quartersByYear.keys()].map((year, i, arr) => (
    <div
      className="flex w-full flex-col items-center justify-center"
      key={year}
    >
      <div
        className={`flex w-full flex-grow justify-evenly border-b-2 border-exxeta-gray-5  font-semibold`}
        id="dates"
      >
        {quartersByYear.get(year)?.map(date => (
          <button
            key={date.count}
            type="button"
            value={date.count}
            onClick={onDateSelect}
            className={`flex p-2 text-lg ${
              date.count === count
                ? "text-black"
                : "text-exxeta-gray-5 decoration-double decoration-2 underline-offset-4 hover:underline"
            }`}
          >
            {date.date}
          </button>
        ))}
      </div>
      <div
        className="flex w-full items-center justify-evenly  p-2 font-semibold  "
        id="year"
      >
        <YearBtn arr={arr} i={i} onClick={onYearSelect} />
        <span className="flex w-9 flex-grow justify-center">{year}</span>
        <YearBtn arr={arr} i={i} isNext onClick={onYearSelect} />
      </div>
    </div>
  ))

/** Map<Year, Quarter[]> */
const groupQuartersByYear = (periods: Date[]): Map<string, Quarter[]> =>
  periods.reduce((acc, curr, i) => {
    const year = curr.getFullYear().toString()
    const arr = acc.get(year) ?? []
    arr.push({ date: format(curr, "QQQ"), count: i })
    acc.set(year, arr)
    return acc
  }, new Map<string, Quarter[]>())

//only shows quartes
interface DatePickerProps {
  count: number
  dates: string[]
  refetch?: (
    variables?:
      | Partial<
          Exact<{
            id: string
            count: number
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetSurveyAndResultsQuery>>
}

export const DatePicker = ({ count, dates, refetch }: DatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [page, setPage] = useState(0)

  const periods = useMemo(() => dates.map(str => new Date(str)), [dates])
  const quartersByYear = useMemo(() => groupQuartersByYear(periods), [periods])

  const dispatch = useAppDispatch()

  const onDateSelect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const index = parseInt(e.currentTarget.value)
    dispatch(setCount(index))
    refetch &&
      void refetch({
        count: index
      })
    setIsOpen(prev => !prev)
  }

  useEffect(() => {
    const yearIndex = Array.from(quartersByYear.keys()).findIndex(
      key => key === periods[count].getFullYear().toString()
    )
    setPage(yearIndex)
  }, [count, periods, quartersByYear])

  const calendar = getCalendar(quartersByYear, count, onDateSelect, setPage)

  return (
    <div className="flex items-center justify-center gap-4">
      <div className=" flex w-full  flex-col">
        {!isOpen && (
          <CurrentDateSelect date={periods[count]} onClick={setIsOpen} />
        )}
        {isOpen && (
          <div
            className=" mt-1 flex w-full justify-evenly overflow-x-auto rounded-md border-2 border-black px-2"
            id="selectMenu"
          >
            {calendar[page]}
          </div>
        )}
      </div>
      <Trash
        width={"1.25rem"}
        height={"1.25rem"}
        className="flex"
        onClick={() => {
          dispatch(setCount(dates.length - 1))
        }}
      />
    </div>
  )
}

interface RangePickerProps {
  dates: string[]
  range: [number, number]
}

export const RangePicker = ({ dates, range }: RangePickerProps) => {
  const [isOpen1, setIsOpen1] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)

  const [page1, setPage1] = useState(0)
  const [page2, setPage2] = useState(0)

  const periods = dates.map(str => new Date(str))

  const dispatch = useAppDispatch()

  const quartersByYear = groupQuartersByYear(periods)

  useEffect(() => {
    const year1 = periods[range[0]].getFullYear().toString()
    const year2 = periods[range[1]].getFullYear().toString()

    const calendarIndex1 = Array.from(quartersByYear.keys()).findIndex(
      key => key === year1
    )
    const calendarIndex2 = Array.from(quartersByYear.keys()).findIndex(
      key => key === year2
    )
    setPage1(calendarIndex1)
    setPage2(calendarIndex2)
  }, [range, periods, quartersByYear])

  const onDateSelect1 = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newCount = parseInt(e.currentTarget.value)
    dispatch(setRange([newCount, range[1]]))

    setIsOpen1(prev => !prev)
  }

  const onDateSelect2 = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newCount = parseInt(e.currentTarget.value)
    dispatch(setRange([range[0], newCount]))

    setIsOpen2(prev => !prev)
  }

  const calendar1 = getCalendar(
    quartersByYear,
    range[0],
    onDateSelect1,
    setPage1
  )
  const calendar2 = getCalendar(
    quartersByYear,
    range[1],
    onDateSelect2,
    setPage2
  )

  return (
    <div className=" flex w-full  flex-col">
      {!isOpen1 && !isOpen2 && (
        <div className="flex gap-3">
          <CurrentDateSelect date={periods[range[0]]} onClick={setIsOpen1} />
          <span>-</span>
          <CurrentDateSelect date={periods[range[1]]} onClick={setIsOpen2} />
        </div>
      )}
      {isOpen1 && (
        <div
          className=" mt-1 flex w-full justify-evenly overflow-x-auto rounded-md border-2 border-black px-2"
          id="selectMenu"
        >
          {calendar1[page1]}
        </div>
      )}
      {isOpen2 && (
        <div
          className=" mt-1 flex w-full justify-evenly overflow-x-auto rounded-md border-2 border-black px-2"
          id="selectMenu"
        >
          {calendar2[page2]}
        </div>
      )}
    </div>
  )
}
