import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { useGetQuestionResultsQuery } from "../../api/generated"
import { useAppSelector } from "../../app/hooks"
import {
  Box,
  FilterButton,
  FilterModal,
  NavButton,
  ResultList,
  SubHeader
} from "../../components"
import { Config } from "../../config"
import {
  getDetailedChart,
  getPageNavigationDetails,
  getQuestionNavigationDetails
} from "../../scripts/utils"
import { Error, Loading } from "../index"
import TreeSelect from "../../components/TreeSelect"

const Details = () => {
  const { pageId, questionId } = useParams()

  const navigate = useNavigate()
  const { t } = useTranslation(["common", "wfs"])

  const { loading, error, data } = useGetQuestionResultsQuery(
    questionId
      ? {
          variables: {
            surveyId: Config.wfsId,
            questionId: questionId
          }
        }
      : { skip: true }
  )

  const {
    organization: orgStore,
    organization: { selectedManager, showIndirect, root },
    survey: { survey }
  } = useAppSelector(state => state.filters)

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error errorCode={error.message} />
  }

  if (!questionId || !pageId)
    return <Error errorCode="no page or question id or survey found" />

  const { prevPage, currPage, nextPage } = getPageNavigationDetails(
    survey,
    pageId
  )

  if (!currPage) return <Error errorCode={"404"} />

  const { currQuestion, nextQuestionId, prevQuestionId } =
    getQuestionNavigationDetails(currPage, questionId)

  const currentResults = data?.getResultsByQuestion ?? []

  const onQuestionId = (questionId?: string) => {
    questionId && pageId && navigate(`/results/${pageId}/${questionId}`)
  }

  if (!currQuestion) return <Error errorCode={"404"} />

  const filteredResults =
    !selectedManager && showIndirect
      ? [...currentResults]
      : !selectedManager && !showIndirect
      ? currentResults.filter(res => res.managerID === root.id)
      : selectedManager && !showIndirect
      ? currentResults.filter(res => res.managerID === selectedManager.id)
      : currentResults.filter(
          res =>
            selectedManager?.subManagers.includes(res.managerID) ||
            res.managerID === selectedManager?.id
        )

  interface Button {
    className: string
    label: string
    enabled: boolean
    onClick: (questionId?: string) => void
  }

  const nextDimensionPageId = nextPage?.id ?? ""
  const nextDimensionQuestionId = nextPage?.questions[0]?.id ?? ""
  const prevDimensionPageId = prevPage?.id ?? ""
  const prevDimensionQuestionId = prevPage?.questions.slice(-1)[0]?.id ?? ""

  const buttons: Button[] = [
    {
      className: "col-start-1 col-span-1 flex justifyfy-center items-center",
      label: "nav.prevDim",
      enabled: !prevQuestionId && !!prevPage,
      onClick: () =>
        navigate(`/results/${prevDimensionPageId}/${prevDimensionQuestionId}`)
    },
    {
      className: "col-start-1 col-span-1 flex justify-center items-center",
      label: "nav.prevQuest",
      enabled: !!prevQuestionId,
      onClick: () => onQuestionId(prevQuestionId)
    },
    {
      className: "col-start-2 col-span-1 flex justify-center items-center",
      label: "nav.back",
      enabled: true,
      onClick: () => navigate(`/results/${pageId}`)
    },
    {
      className: "col-start-3 col-span-1 flex justify-center items-center",
      label: "nav.nextQuest",
      enabled: !!nextQuestionId,
      onClick: () => onQuestionId(nextQuestionId)
    },
    {
      className: "col-start-3 col-span-1 flex justify-center items-center",
      label: "nav.nextDim",
      enabled: !nextQuestionId && !!nextPage,
      onClick: () =>
        navigate(`/results/${nextDimensionPageId}/${nextDimensionQuestionId}`)
    }
  ]

  const header = t(`wfs:page.${pageId}`)

  return (
    <Box>
      <SubHeader header={header}>
        <FilterButton />
      </SubHeader>
      <div className=" flex h-full  w-full flex-grow flex-col items-center justify-start    ">
        <div className="flex w-full flex-col items-center gap-8 text-center">
          <blockquote className="flex  pt-4 text-center text-xl italic ">
            <p>&quot;{t(`wfs:question.${currQuestion.id}`)}&quot;</p>
          </blockquote>

          <div className="flex w-full items-center justify-center">
            {getDetailedChart(
              survey.firstStart,
              survey.startDays,
              filteredResults,
              currQuestion.qType,
              t(`wfs:question.${currQuestion.id}`)
            )}
          </div>
        </div>
        <ResultList
          results={filteredResults}
          startDates={survey.startDays}
          firstStart={survey.firstStart}
        />
      </div>
      <div className="grid-column mt-auto mb-3 grid w-full grid-cols-3 gap-6 p-3">
        {buttons
          .filter(btn => btn.enabled)
          .map(btn => (
            <div className={btn.className} key={btn.label}>
              <NavButton
                enabled={btn.enabled}
                label={t(btn.label)}
                onClick={btn.onClick}
              />
            </div>
          ))}
      </div>
      <FilterModal>
        <TreeSelect {...orgStore} />
      </FilterModal>
    </Box>
  )
}

export default Details
