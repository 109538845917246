import React from "react"

import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg"
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg"

interface NavButtonProps {
  enabled: boolean
  label: string
  onClick: () => void
  isDimension?: boolean
}

export const NavButton = ({ enabled, label, onClick }: NavButtonProps) => {
  const color = enabled ? "exxeta-gray-5" : "transparent"

  return (
    <button
      onClick={onClick}
      className={`flex h-16 w-24 items-center justify-center p-1 text-lg  text-${color} 
      `}
      disabled={!enabled}
    >
      {label}
    </button>
  )
}

/**
 * To be used in combination with FilterModal (see components/basics/Modal.tsx).
 * Place FilterModal at closing tag e.g. </ body>, </ Box>, etc.
 */
export const FilterButton = () => (
  <label htmlFor="filter-modal" className="focus:bg-white ">
    <FilterIcon width={"2rem"} height={"1.725rem"} fill="#333333" />
  </label>
)

/**
 * To be used in combination with CSVModal (see components/basics/Modal.tsx).
 * Place CSVModal at closing tag e.g. </ body>, </ Box>, etc.
 */
export const CSVButton = () => (
  <button className="flex">
    <label htmlFor="download-modal" className="focus:bg-white ">
      <DownloadIcon width={"2rem"} height={"2rem"} fill="#333" />
    </label>
  </button>
)
