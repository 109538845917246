import React, { useState } from "react"
import { Employee } from "../api/generated"
import { useAppDispatch } from "../app/hooks"
import {
  SelectedManager,
  setSelectedManager,
  toggleTeamView
} from "../features/filter/organziationSlice"
import {
  getAllSubordinates,
  getEmailName,
  searchAndEditTree
} from "../scripts/utils"
import { ReactComponent as CaretRight } from "../assets/icons/caret-right.svg"
import { ReactComponent as CaretDown } from "../assets/icons/caret-down.svg"
import { ReactComponent as Trash } from "../assets/icons/trash.svg"
import { ReactComponent as CheckBoxChecked } from "../assets/icons/checkbox-checked.svg"
import { ReactComponent as CheckBoxEmpty } from "../assets/icons/checkbox-empty.svg"
import { useTranslation } from "react-i18next"
export interface TreeData extends Omit<Employee, "subordinates"> {
  subordinates: TreeData[]
  isExtended: boolean
  canBeExtended: boolean
  checked?: boolean
}
import { impersonation } from "../config"

const CollapseIcon = ({
  node,
  onClick
}: {
  node: TreeData
  onClick: (node: TreeData) => void
}) => {
  if (!node.canBeExtended) return <div className="w-5"></div>
  else if (!node.isExtended)
    return (
      <>
        <CaretRight
          width={"1.25rem"}
          height={"1.25rem"}
          onClick={() => onClick(node)}
        />
      </>
    )
  else
    return (
      <>
        <CaretDown
          width={"1.25rem"}
          height={"1.25rem"}
          onClick={() => onClick(node)}
        />
      </>
    )
}

const TeamCheckbox = ({ isTeamView }: { isTeamView: boolean }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <div className="flex w-full flex-row-reverse items-center gap-2   pt-4 md:justify-between  ">
      <p className="flex  w-full  items-center ">{t("includeEmp")}</p>
      {isTeamView ? (
        <CheckBoxChecked
          width={"1.5rem"}
          height={"1.5rem"}
          onClick={() => dispatch(toggleTeamView())}
        />
      ) : (
        <CheckBoxEmpty
          width={"1.5rem"}
          height={"1.5rem"}
          onClick={() => dispatch(toggleTeamView())}
        />
      )}
    </div>
  )
}

interface TreeSelectProps {
  root: TreeData
  selectedManager?: SelectedManager
  showIndirect: boolean
  isDevPage?: boolean
  handleClick?: () => void
  handleSelect?: (selectedManager: TreeData) => void
}

const TreeSelect = ({
  root,
  selectedManager,
  showIndirect,
  isDevPage = false,
  handleClick,
  handleSelect
}: TreeSelectProps) => {
  const [managerName, setManagerName] = useState<string>(() => {
    if (isDevPage) {
      if (impersonation?.includes("@")) return getEmailName(impersonation)
      else return "-"
    } else return selectedManager?.name ?? root.name
  })

  const [tree, setTree] = useState(selectedManager?.tree ?? root)

  const [isOpen, setIsOpen] = useState(false)

  const dispatch = useAppDispatch()

  const toggleSubordinates = (node: TreeData) => {
    if (node.canBeExtended) {
      const newTree = searchAndEditTree(tree, node, true)
      setTree(newTree)
    }
  }

  const handleDefaultSelect = (selectedManager: TreeData) => {
    setManagerName(selectedManager.name)
    dispatch(
      setSelectedManager({
        tree,
        name: selectedManager.name,
        id: selectedManager.id,
        subManagers: getAllSubordinates(selectedManager).map(sub => sub.id)
      })
    )
  }

  const handleDefaultClick = () => {
    dispatch(setSelectedManager(undefined))
  }

  const onClick = handleClick ?? handleDefaultClick
  const onSelect = handleSelect ?? handleDefaultSelect

  const renderTree = (node: TreeData, level = 0) => {
    return (
      <div
        key={node.id}
        style={{ paddingLeft: `${level.toFixed(2)}rem` }}
        className={` my-2 flex flex-1 flex-col `}
      >
        <div className="flex items-center ">
          <CollapseIcon node={node} onClick={toggleSubordinates} />
          <span
            className={`flex pl-2 text-lg decoration-2 md:hover:underline ${
              node.name === managerName ? "font-semibold" : ""
            } `}
            onClick={() => {
              setManagerName(node.name)
              onSelect(node)
              setIsOpen(prev => !prev)
            }}
          >
            {node.name}
          </span>
        </div>
        {node.isExtended &&
          node.subordinates.map(subordinate =>
            renderTree(subordinate, level + 1)
          )}
      </div>
    )
  }

  return (
    <div className="flex  w-full flex-grow flex-col ">
      <div className="flex  w-full items-center  justify-center  gap-4  text-center  ">
        <input
          type="text"
          readOnly
          className="flex  w-full flex-grow  rounded-md border-2 border-black text-center font-semibold"
          value={managerName}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        />
        <Trash
          width={"1.25rem"}
          height={"1.25rem"}
          className="flex"
          onClick={() => {
            onClick()
            isDevPage ? setManagerName("-") : setManagerName(root.name)
            setTree(root)
          }}
        />
      </div>

      {isOpen
        ? isDevPage && !impersonation
          ? renderTree(tree)
          : tree.subordinates.map(manager => renderTree(manager))
        : null}
      {isOpen && !isDevPage && <div className="divider flex"></div>}
      {!isDevPage && <TeamCheckbox isTeamView={showIndirect} />}
    </div>
  )
}

export default TreeSelect
