import {
  Action,
  ThunkAction,
  configureStore,
  combineReducers
} from "@reduxjs/toolkit"

import organzitaionReducer from "../features/filter/organziationSlice"
import surveyReducer from "../features/filter/surveySlice"

const filters = combineReducers({
  organization: organzitaionReducer,
  survey: surveyReducer
})

export const store = configureStore({
  reducer: {
    filters
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
