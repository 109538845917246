import { AuthenticatedTemplate } from "@azure/msal-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

export const TopHeader = () => {
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  const isHome = pathname === "/#" || pathname === "/"

  const lang = i18n.language === "de" ? "en" : "de"

  return (
    <div className="sticky  top-0 z-20  flex h-12 w-full bg-white font-semibold ">
      <a
        href="#/"
        className={`mr-auto flex  h-full items-center pt-1 text-center text-4xl font-bold  sm:ml-auto sm:pl-12 ${
          isHome ? " " : "underline"
        }`}
      >
        FeedbackHub
      </a>
      <AuthenticatedTemplate>
        <button
          onClick={() => void i18n.changeLanguage(lang)}
          className="flex h-full items-center justify-center px-1  pt-1  text-3xl font-medium uppercase"
        >
          {lang}
        </button>
      </AuthenticatedTemplate>
    </div>
  )
}

interface SubHeaderProps {
  header: string
  children: JSX.Element | JSX.Element[]
}

export const SubHeader = ({ header, children }: SubHeaderProps) => {
  return (
    <div
      className={`sticky top-12  z-10 flex w-full justify-between bg-white pl-1 pt-2 pb-2 text-3xl`}
    >
      <h1 className="flex flex-wrap md:pl-7  ">
        <span>{header}</span>
      </h1>
      <div className="flex  items-center justify-center gap-5 pr-2 ">
        {children}
      </div>
    </div>
  )
}
