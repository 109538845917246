import Chart, { ChartTypeRegistry } from "chart.js/auto"
import React, { useEffect, useRef } from "react"

export type BarChartProps = {
  isSingleChoice: boolean
  questionTitle: string
  choices: string[]
  periods: string[]
  dataArr: number[][]
}

const BarChart = ({
  questionTitle,
  isSingleChoice,
  choices,
  periods,
  dataArr
}: BarChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const colorArr = [
    "#003f5c",
    "#2f4b7c",
    "#665191",
    "#a05195",
    "#d45087",
    "#f95d6a",
    "#ff7c43",
    "#ffa600"
  ]

  const datasetArr = choices.map((el, i) => ({
    label: choices[i],
    backgroundColor: colorArr[i],
    data: dataArr[i] // dataArr[i] : response X for all periods
  }))

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas ? canvas.getContext("2d") : null
    const data = {
      labels: periods,
      datasets: datasetArr
    }

    const config = {
      type: "bar" as keyof ChartTypeRegistry,
      data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: isSingleChoice,
            grid: {
              display: false
            }
          },
          y: {
            stacked: isSingleChoice,
            ticks: {
              callback: (value: number | string): string => `${value}%`
            }
          }
        },

        plugins: {
          title: {
            display: true,
            text: questionTitle
          },
          legend: {
            display: true,
            position: "bottom" as const
          },
          tooltip: {
            callbacks: {}
          }
        }
      },
      plugins: []
    }
    if (!ctx) return
    const chart = new Chart(ctx, config)

    return () => {
      chart.destroy()
    }
  })

  return (
    <div className="relative h-full w-full">
      <canvas ref={canvasRef} />
    </div>
  )
}

export default BarChart
