import React from "react"
import { useTranslation } from "react-i18next"

const FeedbackSent = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-grow flex-row items-center justify-center text-center">
      <h1 className="font-band1s-bold text-sm md:text-3xl">
        {t("feedbackSend")}
      </h1>
    </div>
  )
}

export default FeedbackSent
