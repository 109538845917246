import { Config, impersonation, count } from "./config"
import "./index.css"

import { setContext } from "@apollo/client/link/context"
import {
  AccountInfo,
  AuthError,
  IPublicClientApplication,
  InteractionStatus
} from "@azure/msal-browser"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { Route, Routes } from "react-router-dom"

import { FooterBar, TopHeader } from "./components"
import "./index.css"
import {
  Details,
  Dimensions,
  Error,
  Home,
  Loading,
  SingleDimension,
  SurveySection
} from "./pages"
import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from "@apollo/client"
import Development from "./pages/Development"
import { acquireToken } from "./scripts/MSAL"
import InitQuery from "./components/InitQuery"

const newGqlClient = (
  instance: IPublicClientApplication,
  account: AccountInfo
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = new HttpLink({
    uri: Config.apiURL + "/survey"
  })

  const authMiddleware = setContext(async () => {
    const token = await acquireToken(instance, account) // this can be any sync call
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      } as Record<string, string | number>
    }

    if (Config.isDev) {
      impersonation && (headers.headers.Impersonate = impersonation.trim())
      count && (headers.headers["Count-Override"] = count)
    }

    return headers
  })

  return new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: Config.isDev,
    link: from([authMiddleware, httpLink])
  })
}

const App = () => {
  const { inProgress, instance, accounts } = useMsal()
  const [token, setToken] = useState("")
  const [error, setError] = useState<AuthError | string>()
  const [isLoading, setIsLoading] = useState(true)

  const client = newGqlClient(
    instance,
    instance.getActiveAccount() ?? accounts[0]
  )

  if (token === "")
    instance
      .handleRedirectPromise()
      .then(res => {
        res && setToken(res.accessToken)
      })
      .catch(e => {
        setError(
          e instanceof AuthError
            ? e
            : "unknown error while handling login redirect"
        )
      })

  useEffect(() => {
    if (inProgress === InteractionStatus.None && token === "" && !error) {
      void instance
        .ssoSilent({
          scopes: [...Config.msalScopes]
        })
        .then(res => {
          setToken(res.accessToken)
        })
        .catch(() => {
          instance
            .loginRedirect({
              scopes: [...Config.msalScopes],
              redirectUri: Config.postLoginRedirectUri,
              redirectStartPage: Config.postLoginRedirectUri + "#/"
            })
            .catch(e =>
              setError(
                e instanceof AuthError
                  ? e
                  : "unknown error while initiating login redirect"
              )
            )
        })
    }
  }, [error, inProgress, instance, token])

  if (error && error instanceof AuthError) {
    switch (error.errorCode) {
      case "popup_window_error":
        return (
          <Error
            errorCode={
              "failed to log in\nplease make sure pop ups and redirects are not disabled in your browser"
            }
          />
        )
      case "user_cancelled":
        return (
          <Error
            errorCode={
              "failed to log in\nthe log in process was cancelled\n, reload the window to try again"
            }
          />
        )
      default: {
        return (
          <Error
            errorCode={`error message: ${error.errorMessage}\n
           error code: ${error.errorCode}`}
          />
        )
      }
    }
  }

  if (error) return <Error errorCode={error} />
  return (
    <>
      <AuthenticatedTemplate>
        <ApolloProvider client={client}>
          <main className="box-border flex min-h-full flex-col ">
            <TopHeader />
            <InitQuery onLoading={setIsLoading} />
            {isLoading ? (
              <Loading />
            ) : (
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/wfs" element={<SurveySection />} />
                {Config.isDev && (
                  <Route path="/dev" element={<Development />} />
                )}
                <Route path="/survey/:surveyId/" element={<SurveySection />} />
                <Route path="/results">
                  <Route path="" element={<Dimensions />} />
                  <Route path=":pageId" element={<SingleDimension />} />
                  <Route path=":pageId/:questionId" element={<Details />} />
                </Route>
                <Route path="/*" element={<Home />} />
              </Routes>
            )}
            <FooterBar />
          </main>
        </ApolloProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {inProgress != InteractionStatus.None ? (
          <div className="flex h-full flex-grow flex-row items-center justify-center text-center">
            <p>
              a login window will open shortly, please make sure pop ups and
              redirects are not disabled in your browser
            </p>
          </div>
        ) : (
          <div className="flex h-full flex-grow flex-row items-center justify-center text-center">
            <p>You need to be logged in to access this site.</p>
          </div>
        )}
      </UnauthenticatedTemplate>
    </>
  )
}

export default App
