import { useApolloClient } from "@apollo/client"
import React, { useState } from "react"
import { ReactComponent as XMark } from "../../assets/icons/x-mark.svg"

import { saveCsv } from "../../scripts/csv"
import { Survey } from "../../api/generated"
import { useTranslation } from "react-i18next"

import { SelectedManager } from "../../features/filter/organziationSlice"

interface FilterModalProps {
  children: JSX.Element | JSX.Element[]
}

interface ModalProps {
  id: string
  children: JSX.Element | JSX.Element[]
  header: string
  showClose?: boolean
}

const Modal = ({ children, id, header }: ModalProps) => {
  return (
    <>
      <input type="checkbox" id={id} className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <label
          htmlFor={id}
          className={"absolute h-screen w-screen cursor-pointer"}
        />
        <div className="modal-box relative">
          <label
            htmlFor={id}
            className="text-md absolute top-4 right-4 cursor-pointer"
          >
            <XMark width={"0.8rem"} height={"0.8rem"} />
          </label>
          <h1 className="mb-5 text-center text-xl text-black">{header}</h1>
          {children}
        </div>
      </div>
    </>
  )
}

export const ContactModal = () => {
  const { t } = useTranslation()
  return (
    <Modal id="contactModal" header={t("contact")}>
      <div className="px-auto flex w-full items-center justify-center">
        <div className="flex w-fit flex-col items-stretch  gap-4  text-exxeta-gray-5">
          <span className="flex gap-2">
            <a
              href="mailto:alexander.frank@exxeta.com"
              target="_blank"
              rel="noopener noreferrer"
              className="link-hover link flex"
            >
              Frank, Alexander
            </a>
            <p className="flex text-black">(Project Lead)</p>
          </span>
          <span className="flex gap-2">
            <a
              href="mailto:feyez.ben.hamouda@exxeta.com"
              target="_blank"
              rel="noopener noreferrer"
              className="link-hover link flex"
            >
              Ben Hamouda, Feyez
            </a>
            <p className="flex text-black">(Backend)</p>
          </span>
          <span className="flex gap-2">
            <a
              href="mailto:danny.becker@exxeta.com"
              target="_blank"
              rel="noopener noreferrer"
              className="link-hover link flex"
            >
              Becker, Danny
            </a>
            <p className="flex text-black">(Frontend)</p>
          </span>

          <span className="flex gap-2">
            <a
              href="mailto:andreas.vorobjev@exxeta.com"
              target="_blank"
              rel="noopener noreferrer"
              className="link-hover link flex"
            >
              Vorobjev, Andreas
            </a>
            <p className="flex text-black">(Frontend)</p>
          </span>
        </div>
      </div>
    </Modal>
  )
}

/**
 * To be used in combination with FilterButton (see components/basics/Buttons.tsx).
 * Place FilterModal at closing tag e.g. </ body>, </ Box>, etc.
 */
export const FilterModal = ({ children }: FilterModalProps) => (
  <Modal id="filter-modal" header="Filter" showClose>
    <div className="flex flex-col gap-4">{children}</div>
  </Modal>
)

/**
 * To be used in combination with CSVButton (see components/basics/Buttons.tsx).
 * Place CSVModal at closing tag e.g. </ body>, </ Box>, etc.
 */

interface CSVModalProps {
  survey: Survey
  employees: string[] | undefined
  selectedManager: SelectedManager | undefined
  showIndirect: boolean
}

export const CSVModal = ({
  survey,
  employees,
  selectedManager,
  showIndirect
}: CSVModalProps) => {
  const [loading, setLoading] = useState<number>()
  const client = useApolloClient()
  const { t } = useTranslation()
  const onCSVDownload = () => {
    void (async () => {
      await saveCsv(
        ";",
        true,
        val => setLoading(val),
        client,
        survey,
        employees,
        selectedManager,
        showIndirect
      )
      //wait for dom to render label element, else callback won't work
      setTimeout(
        () => document.getElementById("modal-download-label")?.click(),
        1
      )
    })()
  }
  return (
    <Modal id="download-modal" header={t("csvDownload")}>
      <div className="flex h-full w-full justify-around">
        {!loading && (
          <>
            <button
              className="flex w-16  items-center justify-center rounded-md border-2 border-black p-2 text-center uppercase"
              onClick={onCSVDownload}
            >
              {t("yes")}
            </button>
            <button className="flex w-16 items-center justify-center rounded-md border-2 border-black p-2 text-center uppercase">
              <label id="modal-download-label" htmlFor="download-modal">
                {t("no")}
              </label>
            </button>
          </>
        )}
        {loading && (
          <p className="self-center font-Sen-Bold text-xl">
            {Math.trunc(loading)}%
          </p>
        )}
      </div>
    </Modal>
  )
}
