import { TooltipItem } from "chart.js"
import Chart, { ChartTypeRegistry } from "chart.js/auto"
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100"
import { ExtendedChartData } from "chartjs-plugin-stacked100/build/types"
import React, { useEffect, useRef, useState } from "react"

import i18n from "../../i18n"

Chart.register(ChartjsPluginStacked100)

export type Amounts = [number, number, number, number] // disagree, partially disagree , partially agree , agree

export type BarData = {
  label: string
  amount: Amounts
  abstained: number
  isEmpty: boolean
}
interface MiniStackedBarChartProps {
  barData: BarData[]
}

const generateLabel = (chart: Chart) => (
  <ListItem {...(chart.data as ExtendedChartData)} />
)

const ListItem = (data: ExtendedChartData) => {
  const { calculatedData, originalData } = data
  if (calculatedData && originalData) {
    return (
      <div className="flex justify-center gap-4 text-center text-sm">
        {data.datasets.map((dataset, i) => {
          if (calculatedData[i][0] === 0) return null
          const color = dataset.backgroundColor as string
          return (
            <div
              key={i}
              className={"flex flex-col items-center justify-center"}
            >
              <span style={{ color: color }}>{dataset.label}</span>
              <span style={{ color: color }}>
                {Math.abs(calculatedData[i][0])}%
              </span>
              <span style={{ color: color }}>
                ({Math.abs(originalData[i][0] as number)})
              </span>
            </div>
          )
        })}
      </div>
    )
  } else return null
}

const sortAmounts = (arrays: number[][]) =>
  arrays[0].map((_, i) => arrays.map(array => array[i]))

const MiniStackedBarChart = ({ barData }: MiniStackedBarChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [legend, setLegend] = useState<JSX.Element>()
  const filteredData = barData.filter(el => !el.isEmpty)

  const allAmounts = sortAmounts(filteredData.map(el => el.amount))

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas ? canvas.getContext("2d") : null
    const dataSets = [
      {
        order: 1,
        label: i18n.t("slider.disagree"),
        backgroundColor: "#EB0032",
        data: allAmounts[0]
      },
      {
        order: 0,
        label: i18n.t("slider.partDisagree"),
        backgroundColor: "#E69600",
        data: allAmounts[1]
      },
      {
        order: 2,
        label: i18n.t("slider.partAgree"),
        backgroundColor: "#00AA00",
        data: allAmounts[2]
      },
      {
        order: 3,
        label: i18n.t("slider.agree"),
        backgroundColor: "#008700",
        data: allAmounts[3]
      }
    ]
    const data = {
      labels: filteredData.map(el => el.label),
      datasets: dataSets
    }

    const config = {
      type: "bar" as keyof ChartTypeRegistry,
      data,

      options: {
        skipNull: true,
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y" as const,
        beginnAtZero: false,
        scales: {
          x: {
            display: true,
            stacked: true,
            ticks: {
              callback: (val: string | number) => {
                if (typeof val === "number") {
                  return Math.abs(val)
                }
              },
              color: "black",
              font: {
                weight: "normal",
                size: 14,
                family: "Sen"
              }
            }
          },
          y: {
            stacked: true,
            position: "left",
            display: true,
            grid: {
              display: false,
              borderWidth: 0,
              borderColor: "black",
              z: 100
            },
            title: {
              display: true,
              text: i18n.t("period_other"),
              padding: 8
            },
            ticks: {
              color: "black",
              font: {
                weight: "bold",
                size: 14,
                family: "Sen"
              }
            }
          },
          y1: {
            stacked: true,
            position: "right" as const,
            display: true,
            grid: {
              display: false,
              borderWidth: 0,
              borderColor: "black",
              z: 100
            },

            title: {
              display: true,
              text: i18n.t("pctIndicator.abstained"),
              padding: 8
            },

            ticks: {
              callback: (_: string | number, i: number) => {
                return filteredData[i].abstained
              },
              color: "black",
              font: {
                weight: "bold",
                size: 14,
                family: "Sen"
              }
            }
          }
        },
        plugins: {
          stacked100: { enable: true, replaceTooltipLabel: false },
          datalabels: {
            display: false
          },
          legend: {
            display: false
          },
          tooltip: {
            enabled: !(filteredData.length === 1),
            callbacks: {
              label: (context: TooltipItem<"bar">) => {
                context.chart.tooltip && (context.chart.tooltip.title = [])
                const label = context.dataset.label ?? ""
                const pct = context.formattedValue.toString().replace("-", "")
                return ` ${label} ${pct}%(${(context.raw as number)
                  .toString()
                  .replace("-", "")})`
              }
            }
          }
        }
      }
    }
    if (!ctx) return
    const chart = new Chart(ctx, config)
    if (filteredData.length == 1) {
      setLegend(generateLabel(chart))
    } else {
      setLegend(undefined)
    }

    return () => {
      chart.destroy()
    }
  }, [allAmounts, barData, filteredData])

  return (
    <div className="h-full w-full md-max:w-screen">
      {/* empty div around canvas stops it from growing */}
      <div>
        <canvas ref={canvasRef} />
      </div>
      {legend && legend}
    </div>
  )
}

export default MiniStackedBarChart
