import React from "react"

import { formatAmount, roundToOneDecimal } from "../../scripts/utils"

export interface PercentageIndicatorProps {
  amount: number
  abstained: number
  approval?: number
  explanation?: string
  change?: number
  isText?: boolean
}

const PercentageIndicator = ({
  amount,
  approval,

  change,
  isText
}: PercentageIndicatorProps) => {
  const pctTextColour =
    approval !== undefined
      ? approval < 30
        ? "text-[#888888]"
        : approval < 70
        ? "text-exxeta-gray-5"
        : "text-black"
      : ""
  const pctText =
    (approval !== undefined && !isNaN(approval) && approval.toString()) || "-"
  if (change !== undefined && !isNaN(change)) change = roundToOneDecimal(change)

  const changeTextColour = `${
    change
      ? change < 0
        ? "text-exxeta-signal-red"
        : change > 0
        ? "text-exxeta-signal-green"
        : ""
      : ""
  }`

  const showSign = pctText !== "-"

  const changeText = !change
    ? undefined
    : change < 0
    ? `${change}`
    : change > 0
    ? `+${change}`
    : ""

  const symbol = isText ? (amount < 1000 ? "x" : "k") : "%"

  return (
    <div className="flex  items-center justify-center  ">
      <p className="flex flex-col items-center justify-center text-center">
        <span
          className={pctTextColour + ` ${change ? "border-b-2" : ""}  text-4xl`}
        >
          {showSign ? Math.trunc(+pctText) : formatAmount(amount)}

          <span className="text-xl">{symbol}</span>
        </span>
        {change && (
          <span
            className={`${
              changeTextColour ?? ""
            } flex w-full items-center justify-center pr-2 text-center font-semibold`}
          >
            {changeText}
            <span className="text-xs ">%</span>
          </span>
        )}
      </p>
    </div>
  )
}

export default PercentageIndicator
