import {
  GetQuestionResultsDocument,
  GetQuestionResultsQuery,
  GetQuestionResultsQueryVariables,
  Survey
} from "../api/generated"
import { Config } from "../config"
import { getDateFromCount, getPeriod, getTextFromResultAnswer } from "./utils"
import { ApolloClient } from "@apollo/client"
import i18next from "i18next"
import { SelectedManager } from "../features/filter/organziationSlice"

export const saveCsv = async <T>(
  delimiter: string,
  useHeader: boolean,
  progressCallback: (progress?: number) => void,
  client: ApolloClient<T>,
  survey: Survey,
  employees: string[] | undefined,
  selectedManager: SelectedManager | undefined,
  isTeamView: boolean
) => {
  const rows = []
  const questionAmount = survey.pages.reduce(
    (acc, elem) => elem.questions.length + acc,
    0
  )

  let currentPct = 0
  const pctPerQuestion = (1 / questionAmount) * 100

  if (useHeader) {
    const header = `page${delimiter}question${delimiter}quarter${delimiter}manager${delimiter}answer${delimiter}remark`
    rows.push(header)
  }

  for (const page of survey.pages) {
    const pageText = i18next.t(`wfs:page.${page.id}`)
    for (const question of page.questions) {
      const questionText = i18next.t(`wfs:question.${question.id}`)
      const queryRes = await client.query<
        GetQuestionResultsQuery,
        GetQuestionResultsQueryVariables
      >({
        query: GetQuestionResultsDocument,
        variables: {
          surveyId: survey.id,
          questionId: question.id,
          count: null
        },
        fetchPolicy: Config.isDev ? "no-cache" : "cache-first"
      })

      if (queryRes.error != undefined) {
        console.error(queryRes.error.message)
      }

      let results = queryRes.data.getResultsByQuestion
      if (selectedManager) {
        results = queryRes.data.getResultsByQuestion.filter(data => {
          if (isTeamView) {
            return (
              data.managerID === selectedManager.id ||
              employees?.includes(data.managerID)
            )
          } else {
            return data.managerID === selectedManager.id
          }
        })
      }

      for (const result of results ?? []) {
        const temp = getTextFromResultAnswer(result.answer)
        const resText = temp[0]
        const remark = temp[1] ?? ""
        const date = getPeriod(
          getDateFromCount(survey.startDays, survey.firstStart, result.count)
        )

        rows.push(
          pageText +
            delimiter +
            questionText +
            delimiter +
            date +
            delimiter +
            result.managerName +
            delimiter +
            resText +
            delimiter +
            remark
        )
      }
      currentPct += pctPerQuestion
      progressCallback(currentPct)
    }
  }

  const csvText = rows.join("\n")
  const BOM = "\uFEFF"
  const today = new Date()

  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([BOM + csvText], { type: "text/csv;charset=utf-8" })

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob)

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a")

  // Passing the blob downloading url
  a.setAttribute("href", url)

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute(
    "download",
    `WFS-Results-${
      isTeamView ? "extended" : ""
    } ${today.getDate()}_${today.getMonth()}_${today.getFullYear()}.csv`
  )

  // Performing a download with click
  a.click()
  progressCallback(undefined)
}
