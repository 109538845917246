import { ResultIn, Survey } from "../api/generated"
import { QCard, QCardProps } from "./QCard"
import i18n from "../i18n"

export type QFormPage = { id: string; heading: string; qCards: QCard[] }
export type QForm = QFormPage[]

// generateForm generates a QForm from the given Survey
export const generateForm = (obj: Survey): QForm => {
  const retForm: QForm = []
  const pages = [...obj.pages]
  pages.map(inPage => {
    const page: QFormPage = {
      id: inPage.id,
      heading: i18n.t(`wfs:page.${inPage.id}`),
      qCards: []
    }
    const questions = [...inPage.questions]
    questions.map(inCard => {
      const card = { ...inCard }
      let props: QCardProps
      switch (card.qType) {
        case "slider":
          props = {
            type: card.qType,
            value: 2,
            remark: null
          }
          break
        case "text":
          props = {
            type: card.qType,
            value: null
          }
          break
        case "singleChoice":
          props = {
            type: card.qType,
            value: null,
            choices: [],
            remark: null
          }
          card.answerOptions?.map(option =>
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
            props.choices.push({
              id: option,
              text: i18n.t(`wfs:answer.${option}`)
            })
          )
          break
        case "multipleChoice":
          props = {
            type: card.qType,
            value: [],
            choices: [],
            remark: null
          }
          card.answerOptions?.map(option =>
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
            props.choices.push({
              id: option,
              text: i18n.t(`wfs:answer.${option}`)
            })
          )
          break
        default:
          throw "unknown qType"
      }
      page.qCards.push(
        new QCard(i18n.t(`wfs:question.${card.id}`), props, card.id, false)
      )
    })
    retForm.push(page)
  })
  return retForm
}

//only returns non-empty Results
export const getResults = (form: QForm): ResultIn[] => {
  const retVals: ResultIn[] = []
  for (const page of form) {
    for (const card of page.qCards) {
      let res: ResultIn | undefined
      if (card.abstained) {
        if (card.props.type !== "text") {
          res = {
            pageID: page.id,
            questionID: card.questionId,
            abstained: {
              remark: card.props.remark
            }
          }
        } else {
          res = {
            pageID: page.id,
            questionID: card.questionId,
            abstained: {}
          }
        }
        retVals.push(res)
      } else if (card.props.value != null) {
        switch (card.props.type) {
          case "text":
            res = {
              pageID: page.id,
              questionID: card.questionId,
              text: {
                value: card.props.value
              }
            }
            break
          case "slider":
            {
              if (
                card.props.value >= 0 &&
                card.props.value < 5 &&
                card.props.value != 2
              ) {
                res = {
                  pageID: page.id,
                  questionID: card.questionId,
                  slider: {
                    value: card.props.value as 0 | 1 | 3 | 4,
                    remark: card.props.remark
                  }
                }
              }
            }
            break
          case "singleChoice": {
            res = {
              pageID: page.id,
              questionID: card.questionId,
              singleChoice: {
                id: card.props.value,
                remark: card.props.remark
              }
            }
            break
          }
          case "multipleChoice": {
            res = {
              pageID: page.id,
              questionID: card.questionId,
              multipleChoice: {
                ids: card.props.value,
                remark: card.props.remark
              }
            }
            break
          }
        }
        res && retVals.push(res)
      }
    }
  }
  return retVals
}
