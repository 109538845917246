import React from "react"

export interface TextCardProps {
  charLimit?: number
  value: string | null
  setValue(newValue: string, hasNoText?: boolean): void
}

const TextCard = (props: TextCardProps) => {
  return (
    <textarea
      className="focus:black w-full rounded-md border-2  border-black  p-2 text-xs shadow-sm md:text-base"
      onChange={event => {
        const hasNoText = !event.target.value || event.target.value === ""
        props.setValue(event.target.value, hasNoText)
      }}
      value={props.value || ""}
      rows={3}
      maxLength={props.charLimit}
    />
  )
}

export default TextCard
