import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"
import { Config } from "./config"

const wfsPath = Config.isDev ? "-dev" : "-prod"
const loadPath = `../locales/{{ns}}${wfsPath}/{{lng}}.json`

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    debug: false,
    backend: {
      loadPath
    },
    ns: ["common", "wfs"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false // react already protects from xss
    }
  })

export default i18n
