import { ApolloClient, ApolloError, FetchResult, gql } from "@apollo/client"

import { Result } from "../scripts/utils"
import {
  AddResultsMutation,
  AddResultsMutationVariables,
  ResultIn
} from "./generated"

gql`
  mutation sendFeedback($feedback: String!) {
    sendFeedback(feedback: $feedback)
  }
`

const addResultsMutation = gql`
  mutation addResults($surveyID: String!, $results: [ResultIn!]!) {
    addResults(surveyID: $surveyID, results: $results)
  }
`

export type MutationError =
  | { type: "apolloError"; value: ApolloError }
  | { type: "addResultsError"; message: string | null }

// addResults assumes that all QCards inside the QForm have been filled out
export const publishResults = async <T>(
  surveyId: string,
  results: ResultIn[],
  client: ApolloClient<T>
): Promise<Result<null, MutationError>> => {
  let res: FetchResult<AddResultsMutation>
  try {
    res = await client.mutate<AddResultsMutation, AddResultsMutationVariables>({
      mutation: addResultsMutation,
      variables: {
        surveyID: surveyId,
        results: results
      }
    })
  } catch (e) {
    return {
      ok: false,
      error: {
        type: "apolloError",
        value: e as ApolloError
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (res.data?.addResults?.length != results.length) {
    return {
      ok: false,
      error: {
        type: "addResultsError",
        message: null
      }
    }
  }

  return { ok: true, value: null }
}
