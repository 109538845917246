import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  Box,
  DatePicker,
  DimensionListItem,
  FilterButton,
  FilterModal,
  NavButton,
  SubHeader
} from "../../components/index"
import {
  getPageNavigationDetails,
  processDirectQuestionResults,
  processIndirectQuestionResults,
  processIndirectQuestionResultsForManager
} from "../../scripts/utils"
import { Error } from "../index"
import TreeSelect, { TreeData } from "../../components/TreeSelect"

import {
  Question,
  Survey,
  useGetSurveyAndResultsQuery
} from "../../api/generated"
import { SelectedManager } from "../../features/filter/organziationSlice"
import { Config } from "../../config"
import { setSurvey } from "../../features/filter/surveySlice"

const getPctIndicatorProps = (
  questions: Question[],
  showIndirect: boolean,
  root:
    | (SelectedManager & { selected: true })
    | (TreeData & { selected: false })
) => {
  if (root.selected) {
    return showIndirect
      ? processIndirectQuestionResultsForManager(questions, root)
      : processDirectQuestionResults(questions, root.id)
  }

  return showIndirect
    ? processIndirectQuestionResults(questions)
    : processDirectQuestionResults(questions, root.id)
}

const SingleDimension = () => {
  const { pageId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation(["common", "wfs"])

  const {
    survey: { count, dates, survey },
    organization: { selectedManager, showIndirect, root },
    organization: orgStore
  } = useAppSelector(state => state.filters)

  const dispatch = useAppDispatch()

  const { refetch } = useGetSurveyAndResultsQuery({
    variables: { id: Config.wfsId, count },
    fetchPolicy: "cache-first",
    onCompleted: data => {
      if (data.getSurvey) dispatch(setSurvey(data.getSurvey as Survey))
    }
  })

  if (!pageId) return <Error errorCode="no pageId found" />

  const { prevPage, currPage, nextPage } = getPageNavigationDetails(
    survey,
    pageId
  )

  if (!currPage) return <Error errorCode={"404"} />
  const header = pageId ? t(`wfs:page.${pageId}`) : t("header.dimension")
  const onPageId = (pageId?: string) => {
    pageId && navigate(`/results/${pageId}`)
  }

  const pctIndicatorProps = getPctIndicatorProps(
    currPage.questions,
    showIndirect,
    selectedManager
      ? { selected: true, ...selectedManager }
      : { selected: false, ...root }
  )

  return (
    <Box>
      <SubHeader header={header}>
        <FilterButton />
      </SubHeader>
      <div
        className={`grid max-w-full grid-cols-12 gap-y-8 gap-x-4 px-3 pt-5 md:pl-8`}
      >
        {currPage.questions.map((question, i) => (
          <DimensionListItem
            page={currPage}
            question={question}
            pctIndicatorProps={pctIndicatorProps[i]}
            key={question.id}
          />
        ))}
      </div>
      <div className="flex w-full items-center justify-center">
        <div className=" mb-3 mt-auto flex w-full max-w-3xl flex-row items-center justify-evenly self-end px-3 pt-8">
          <NavButton
            onClick={() => onPageId(prevPage?.id)}
            enabled={!!prevPage}
            label={t("nav.prevDim")}
          />
          <NavButton
            onClick={() => navigate(`/results/`)}
            enabled={true}
            label={t("nav.back")}
          />
          <NavButton
            onClick={() => onPageId(nextPage?.id)}
            enabled={!!nextPage}
            label={t("nav.nextDim")}
          />
        </div>
      </div>
      <FilterModal>
        <DatePicker count={count} dates={dates} refetch={refetch} />
        <TreeSelect {...orgStore} />
      </FilterModal>
    </Box>
  )
}

export default SingleDimension
