import React, { useRef } from "react"
import useCountdown from "../scripts/hooks/useCountdown"
import { useTranslation } from "react-i18next"
import { getDateFromCount } from "../scripts/utils"

const START_HOUR = 3

interface ISurveyCountdownProps {
  startDates: Array<string>
  firstStart: number
  count: number
}

const SurveyCountdown = ({
  startDates,
  firstStart,
  count
}: ISurveyCountdownProps) => {
  const nextStart = getDateFromCount(startDates, firstStart, count)
  nextStart.setHours(START_HOUR)

  const targetDate = useRef(nextStart)
  const { days, hours, minutes, seconds } = useCountdown({
    targetDate: targetDate.current
  })
  const { t } = useTranslation()

  const isDateInPast = () => {
    return days < 0 || hours < 0 || minutes < 0 || seconds < 0
  }

  return (
    <div className=" box-border flex w-full flex-col items-center  justify-center self-center md:mt-8 md:w-[80%] md:pb-2 lg:w-[70%]  2xl:w-[55%] md-max:flex-grow ">
      {isDateInPast() ? (
        <>
          <h1 className={"mb-5 text-center text-3xl"}>
            {t("countdown.inPreparation")}
          </h1>
          <p className={"text-md w-full px-8 text-center sm:w-1/2"}>
            {t("countdown.inPreparationDescription")}
          </p>
        </>
      ) : (
        <>
          <h1 className={"mb-5 text-center text-3xl"}>{t("countdown.text")}</h1>
          <div className="grid auto-cols-max grid-flow-col gap-5 text-center">
            <div className="flex flex-col">
              <span className="font-mono countdown text-5xl">
                {/* @ts-ignore */}
                <span style={{ "--value": days }}></span>
              </span>
              {t("countdown.days")}
            </div>
            <div className="flex flex-col">
              <span className="font-mono countdown text-5xl">
                {/* @ts-ignore */}
                <span style={{ "--value": hours }}></span>
              </span>
              {t("countdown.hours")}
            </div>
            <div className="flex flex-col">
              <span className="font-mono countdown text-5xl">
                {/* @ts-ignore */}
                <span style={{ "--value": minutes }}></span>
              </span>
              {t("countdown.minutes")}
            </div>
            <div className="flex flex-col">
              <span className="font-mono countdown text-5xl">
                {/* @ts-ignore */}
                <span style={{ "--value": seconds }}></span>
              </span>
              {t("countdown.seconds")}
            </div>
          </div>
          <p className={"text-md mt-2"}>({nextStart.toLocaleString()})</p>
        </>
      )}
    </div>
  )
}

export default SurveyCountdown
