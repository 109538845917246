import {
  Employee,
  GetSurveyQuery,
  Survey,
  useGetOrganisationQuery,
  useGetSurveyAndResultsLazyQuery,
  useGetSurveyAndResultsQuery
} from "../api/generated"
import { Box, DatePicker } from "../components"
import { Error, Loading } from "./index"
import { useMsal } from "@azure/msal-react"
import React, { Fragment, useState } from "react"
import { convertToTreeData } from "../scripts/utils"
import { createExampleDatasets } from "../scripts/TestDataset"
import { useApolloClient } from "@apollo/client"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import TreeSelect, { TreeData } from "../components/TreeSelect"
import { setSurvey } from "../features/filter/surveySlice"
import { Config, setImpersonation } from "../config"
import { setOrganizationState } from "../features/filter/organziationSlice"

const getSkeletonLocalization = (survey: GetSurveyQuery["getSurvey"]) => {
  type StringToString = {
    [key: string]: string
  }
  const jsonObj = {
    page: {} as StringToString,
    question: {} as StringToString,
    answer: {} as StringToString
  }

  survey?.pages.forEach((page, index) => {
    const pString = `Page ${index}`
    jsonObj.page[page.id] = pString

    page.questions.forEach((question, qIndex) => {
      const qString = `Question ${qIndex}, ${pString}`
      jsonObj.question[question.id] = qString

      question.answerOptions.forEach((answerId, aIndex) => {
        jsonObj.answer[answerId] = `Answer ${aIndex}, ${qString}`
      })
    })
  })

  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([JSON.stringify(jsonObj)], { type: "text/json" })

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob)

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a")

  // Passing the blob downloading url
  a.setAttribute("href", url)

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", `${survey?.id ?? ""}-localization.json`)

  // Performing a download with click
  a.click()
}

export const Development = () => {
  const { instance } = useMsal()
  const client = useApolloClient()
  const actualUser = instance.getAllAccounts()[0].username
  const [isLoading, setIsLoading] = useState(false)
  const [isCreatingExampleData, setIsCreatingExampleData] = useState(false)
  const [localOrgState, setLocalOrgState] = useState<TreeData>()
  const {
    survey: { count, dates, survey },
    organization: orgStore
  } = useAppSelector(state => state.filters)

  const {
    data: orgData,
    loading: orgLoading,
    error: orgError,

    refetch: orgRefetch
  } = useGetOrganisationQuery({
    variables: {
      rootID: "marc.vollmar@exxeta.com"
    },
    onCompleted: data => {
      if (data.getOrganisation && !localOrgState)
        setLocalOrgState(convertToTreeData(data.getOrganisation as Employee))
    }
  })

  const dispatch = useAppDispatch()

  const { refetch: surveyRefetch } = useGetSurveyAndResultsQuery({
    variables: { id: survey.id, count },
    fetchPolicy: "cache-first",
    onCompleted: data => {
      if (data.getSurvey) dispatch(setSurvey(data.getSurvey as Survey))
    }
  })

  const [fetchSurvey] = useGetSurveyAndResultsLazyQuery({
    variables: { id: survey.id, count },
    fetchPolicy: "cache-first",
    onCompleted: data => {
      if (data.getSurvey) dispatch(setSurvey(data.getSurvey as Survey))
    }
  })

  const [selectedPercentFullyAgree, setSelectedPercentFullyAgree] =
    useState<number>(50)
  const [selectedPercentAgree, setSelectedPercentAgree] = useState<number>(20)
  const [selectedPercentDisagree, setSelectedPercentDisagree] =
    useState<number>(10)
  const [selectedPercentFullyDisagree, setSelectedPercentFullyDisagree] =
    useState<number>(10)
  const [selectedPercentAbstain, setSelectedPercentAbstain] =
    useState<number>(10)
  const [selectedPercentComment, setSelectedPercentComment] =
    useState<number>(10)

  if (orgLoading || !orgData?.getOrganisation) {
    return <Loading />
  }

  if (orgError) {
    return <Error errorCode={orgError.message} />
  }

  const createExampleAnswers = (useCount: boolean) => {
    setIsCreatingExampleData(true)
    createExampleDatasets(
      selectedPercentFullyAgree * 0.01,
      selectedPercentAgree * 0.01,
      selectedPercentDisagree * 0.01,
      selectedPercentFullyDisagree * 0.01,
      selectedPercentAbstain * 0.01,
      selectedPercentComment * 0.01,
      survey,
      organization,
      client,
      useCount ? count : undefined
    )
      .catch((err: unknown) => {
        orgData.getOrganisation as Employee
        console.error(err)
      })
      .finally(() => {
        alert("Completed example data creation.")
        setIsCreatingExampleData(false)
      })
  }

  const organization = convertToTreeData(orgData.getOrganisation as Employee)

  const handleClick = () => {
    setImpersonation(undefined)
    setIsLoading(true)
    orgRefetch &&
      orgRefetch({
        rootID: actualUser
      }).then(res =>
        dispatch(setOrganizationState(res.data.getOrganisation as Employee))
      )

    fetchSurvey &&
      void fetchSurvey({
        variables: {
          id: Config.wfsId,
          count
        },
        fetchPolicy: "network-only",
        onCompleted: data => {
          if (data.getSurvey) {
            setIsLoading(false)

            // @ts-ignore
            dispatch(setSurvey(data.getSurvey))
          }
        }
      })
  }

  const handleSelect = (selectedManager: TreeData) => {
    setIsLoading(true)
    setImpersonation(selectedManager.id)
    dispatch(setOrganizationState(selectedManager))
    fetchSurvey &&
      void fetchSurvey({
        variables: {
          id: Config.wfsId,
          count
        },
        fetchPolicy: "network-only",
        onCompleted: data => {
          if (data.getSurvey) {
            setIsLoading(false)

            dispatch(setSurvey(data.getSurvey as Survey))
          }
        }
      })
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <div className="flex flex-col items-center">
        <div className={"m-2 w-full border border-black p-4 text-center"}>
          <h2 className={"pb-4 text-xl"}> impersonate: </h2>
          <TreeSelect
            showIndirect={orgStore.showIndirect}
            selectedManager={orgStore.selectedManager}
            root={localOrgState ?? organization}
            isDevPage
            handleClick={handleClick}
            handleSelect={handleSelect}
          />
        </div>
        <div className={"m-2 w-full border border-black p-4 text-center"}>
          <div className={"w-full  text-center"}>
            <h2 className={"pb-4 text-xl"}> current quarter: </h2>
            <DatePicker count={count} dates={dates} refetch={surveyRefetch} />
          </div>
        </div>
        {window.location.href.includes("localhost") && (
          <Fragment>
            <div
              className={
                "align-center m-2 flex w-full flex-col justify-center border border-black p-4 text-center"
              }
            >
              <p className={"text-md text-red-500"}>
                Fully agree, Agree, Disagree, Fully Disagree and Abstain have to
                add up to exactly 100%. Comment is independent of the other
                fields and can be between 0% and 100%. The comment is always
                &quot;Example dataset - Remark&quot;
              </p>
              <label className={"text-left"}>
                Fully agree(%): Percentage of answers fully agree
              </label>
              <input
                className={"border border-black"}
                value={selectedPercentFullyAgree}
                onChange={evt => {
                  setSelectedPercentFullyAgree(
                    Number.parseFloat(evt.target.value)
                  )
                }}
                type={"number"}
                min={0}
                max={100}
                step={1}
              />
              <label className={"text-left"}>
                Agree(%): Percentage of answers that agree
              </label>
              <input
                className={"border border-black"}
                value={selectedPercentAgree}
                onChange={evt => {
                  setSelectedPercentAgree(Number.parseFloat(evt.target.value))
                }}
                type={"number"}
                min={0}
                max={100}
                step={1}
              />
              <label className={"text-left"}>
                Disagree(%): Percentage of answers that disagree
              </label>
              <input
                className={"border border-black"}
                value={selectedPercentDisagree}
                onChange={evt => {
                  setSelectedPercentDisagree(
                    Number.parseFloat(evt.target.value)
                  )
                }}
                type={"number"}
                min={0}
                max={100}
                step={1}
              />
              <label className={"text-left"}>
                Fully disagree(%): Percentage of answers that fully disagree
              </label>
              <input
                className={"border border-black"}
                value={selectedPercentFullyDisagree}
                onChange={evt => {
                  setSelectedPercentFullyDisagree(
                    Number.parseFloat(evt.target.value)
                  )
                }}
                type={"number"}
                min={0}
                max={100}
                step={1}
              />
              <label className={"text-left"}>
                Abstain(%): Percentage of answers that abstain
              </label>
              <input
                className={"border border-black"}
                value={selectedPercentAbstain}
                onChange={evt => {
                  setSelectedPercentAbstain(Number.parseFloat(evt.target.value))
                }}
                type={"number"}
                min={0}
                max={100}
                step={1}
              />
              <label className={"text-left"}>
                Comment(%): Percentage of Answers getting a comment. Independent
                of the other percentages.
              </label>
              <input
                className={"mb-3 border border-black"}
                value={selectedPercentComment}
                onChange={evt => {
                  setSelectedPercentComment(Number.parseFloat(evt.target.value))
                }}
                type={"number"}
                min={0}
                max={100}
                step={1}
              />
              {isCreatingExampleData && (
                <button
                  disabled={true}
                  className={"align-center flex justify-center"}
                >
                  <div className="h-5 w-5 animate-spin rounded-full border-t-2 border-black"></div>
                  <p>Creating ...</p>
                </button>
              )}
              {!isCreatingExampleData && (
                <div className={"align-center flex w-full justify-between"}>
                  <button onClick={() => createExampleAnswers(true)}>
                    Create period example survey data
                  </button>
                  <button onClick={() => createExampleAnswers(false)}>
                    Create example survey data
                  </button>
                </div>
              )}
            </div>
            <div className={"m-2 w-full border border-black p-4 text-center"}>
              <button onClick={() => getSkeletonLocalization(survey)}>
                Download skeleton survey localization
              </button>
            </div>
          </Fragment>
        )}
      </div>
    </Box>
  )
}

export default Development
