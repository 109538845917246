import React from "react"
import { useTranslation } from "react-i18next"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  Box,
  CSVButton,
  CSVModal,
  DatePicker,
  DimensionListItem,
  FilterButton,
  FilterModal,
  SubHeader
} from "../../components/index"
import {
  processDirectPageResults,
  processIndirectPageResults,
  processIndirectPageResultsForManager
} from "../../scripts/utils"

import TreeSelect, { TreeData } from "../../components/TreeSelect"
import { Page } from "../../api/generated"
import { SelectedManager } from "../../features/filter/organziationSlice"

const getPctIndicatorProps = (
  pages: Page[],
  showIndirect: boolean,
  root:
    | (SelectedManager & { selected: true })
    | (TreeData & { selected: false })
) => {
  if (root.selected) {
    return showIndirect
      ? processIndirectPageResultsForManager(pages, root)
      : processDirectPageResults(pages, root.id)
  }

  return showIndirect
    ? processIndirectPageResults(pages)
    : processDirectPageResults(pages, root.id)
}
import { Survey, useGetSurveyAndResultsQuery } from "../../api/generated"
import { Config } from "../../config"
import { setSurvey } from "../../features/filter/surveySlice"

const Dimensions = () => {
  const { t } = useTranslation(["common", "wfs"])

  const {
    survey: { count, dates, survey },
    organization: { selectedManager, showIndirect, root },
    organization: orgStore
  } = useAppSelector(state => state.filters)

  const dispatch = useAppDispatch()

  const { refetch } = useGetSurveyAndResultsQuery({
    variables: { id: Config.wfsId, count },
    fetchPolicy: "cache-first",
    onCompleted: data => {
      if (data.getSurvey) dispatch(setSurvey(data.getSurvey as Survey))
    }
  })

  const pctIndicatorProps = getPctIndicatorProps(
    survey.pages,
    showIndirect,
    selectedManager
      ? { selected: true, ...selectedManager }
      : { selected: false, ...root }
  )

  return (
    <Box>
      <SubHeader header={t("header.dimensions")}>
        <CSVButton />
        <FilterButton />
      </SubHeader>
      <div className={`grid grid-cols-12 gap-y-5 gap-x-4 px-3 md:w-full`}>
        {survey.pages.map((page, index) => (
          <DimensionListItem
            page={page}
            pctIndicatorProps={pctIndicatorProps[index]}
            key={index}
          />
        ))}
      </div>
      <FilterModal>
        <DatePicker count={count} dates={dates} refetch={refetch} />
        <TreeSelect {...orgStore} />
      </FilterModal>
      <CSVModal
        survey={survey}
        employees={selectedManager?.subManagers}
        selectedManager={selectedManager}
        showIndirect={showIndirect}
      />
    </Box>
  )
}

export default Dimensions
