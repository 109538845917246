import React from "react"
import cloudgif from "../assets/animations/icons8-load-from-cloud.gif"

const Loading = () => (
  <div className="flex flex-grow  items-center justify-center ">
    <img src={cloudgif as string} />
  </div>
)

export default Loading
