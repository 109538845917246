import React from "react"

import type { Choice } from "../../scripts/QCard"

export interface SingleChoiceCardProps {
  currentChoice: string | null // id of the current choice
  setCurrentChoice(newChoice: string): void
  choices: Choice[]
}

const SingleChoiceCard = (props: SingleChoiceCardProps) => {
  return (
    <div className="flex flex-wrap">
      {props.choices.map(choice => (
        <label
          className="mr-12 flex flex-row items-center align-middle first-line:mr-0"
          key={choice.id}
        >
          <input
            className="mr-2 h-4 w-4"
            type="radio"
            checked={choice.id == props.currentChoice}
            onChange={() => props.setCurrentChoice(choice.id)}
          />
          {choice.text}
        </label>
      ))}
    </div>
  )
}

export default SingleChoiceCard
