export let impersonation: string | undefined = undefined
export const setImpersonation = (newImpersonation: string | undefined) =>
  (impersonation = newImpersonation)

export let count: number | undefined = undefined
export const setCount = (newCount: number) =>
  newCount
    ? Number.isInteger(newCount)
      ? (count = newCount)
      : console.error(`count is not an integer value: ${newCount}`)
    : (count = newCount)

export const Config = {
  msalConfig: {
    auth: {
      clientId: "76f723be-22a9-42d9-a46c-10fbb6e1e8bf",
      authority:
        "https://login.microsoftonline.com/338159a0-dd8f-45af-9672-faaa54bc6b24",
      postLogoutRedirectUri: "https://feedbackhub.cdos.exxeta.info/#/"
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false
    }
  },
  apiURL: "https://w78kiqt1hh.execute-api.eu-central-1.amazonaws.com/prod",
  msalScopes: ["api://6eb975db-a32f-4534-8145-a4fe4b258299/.default"],
  wfsId: "7020db4b-16de-4f14-b303-a9f04bcdc662",
  postLoginRedirectUri: "https://feedbackhub.cdos.exxeta.info/",
  isDev: true
} as const
