import React, { PropsWithChildren } from "react"

const Box = ({ children }: PropsWithChildren) => {
  return (
    <div className=" box-border flex w-full flex-col md:mt-8  md:w-[80%]  md:items-center md:justify-center md:self-center md:rounded-lg md:border-2 md:border-black md:pb-2 lg:w-[70%]  2xl:w-[55%] md-max:flex-grow ">
      {children}
    </div>
  )
}

export default Box
