import { ApolloClient } from "@apollo/client"
import { setCount, setImpersonation } from "../config"
import { Employee, ResultIn, Survey } from "../api/generated"
import { MutationError, publishResults } from "../api/mutations"
import { Result } from "./utils"

const REMARK = "Example dataset - Remark"
const ABSTAIN = -1

/* const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
} */

const isBetween = (check: number, min: number, max: number) => {
  return check >= min && check <= max
}

const shouldRemark = (percent: number) => {
  return Math.random() < percent
}

const getResultFromPercent = (
  fullyAgree: number,
  agree: number,
  disagree: number,
  fullyDisagree: number,
  abstain: number
): 0 | 1 | 3 | 4 | -1 => {
  const random = Math.random()
  const agreeRange = fullyAgree + agree
  const disagreeRange = agreeRange + disagree
  const fullyDisagreeRange = disagreeRange + fullyDisagree
  const abstainRange = fullyDisagreeRange + abstain

  if (isBetween(random, 0, fullyAgree)) {
    return 4
  } else if (isBetween(random, fullyAgree, agreeRange)) {
    return 3
  } else if (isBetween(random, agreeRange, disagreeRange)) {
    return 1
  } else if (isBetween(random, disagreeRange, fullyDisagreeRange)) {
    return 0
  } else if (isBetween(random, fullyDisagreeRange, abstainRange)) {
    return ABSTAIN
  } else {
    return 4
  }
}

const createResults = (
  survey: Survey,
  fullyAgree: number,
  agree: number,
  disagree: number,
  fullyDisagree: number,
  abstain: number,
  comment: number
): ResultIn[] => {
  const results: ResultIn[] = []
  survey.pages.forEach(page => {
    page.questions.forEach(question => {
      results.push(
        createResult(
          page.id,
          question.id,
          fullyAgree,
          agree,
          disagree,
          fullyDisagree,
          abstain,
          comment
        )
      )
    })
  })

  return results
}

const createResult = (
  pageId: string,
  questionId: string,
  fullyAgree: number,
  agree: number,
  disagree: number,
  fullyDisagree: number,
  abstain: number,
  comment: number
): ResultIn => {
  const remark = shouldRemark(comment) ? REMARK : undefined
  const result = getResultFromPercent(
    fullyAgree,
    agree,
    disagree,
    fullyDisagree,
    abstain
  )

  if (result === ABSTAIN) {
    return {
      abstained: { remark: remark },
      pageID: pageId,
      questionID: questionId
    }
  } else {
    return {
      pageID: pageId,
      questionID: questionId,
      slider: { value: result, remark: remark }
    }
  }
}

const getAllEmployees = (employee: Employee, list: Array<string>): void => {
  list.push(employee.id)
  employee.subordinates.forEach(subEmployee => {
    getAllEmployees(subEmployee, list)
  })
}

export const createExampleDatasets = async (
  fullyAgree: number,
  agree: number,
  disagree: number,
  fullyDisagree: number,
  abstain: number,
  comment: number,
  survey: Survey,
  organisation: Employee,
  client: ApolloClient<object>,
  count?: number
): Promise<void> => {
  const employees: Array<string> = []
  getAllEmployees(organisation, employees)

  alert(
    `
        Please verify, that you want to add example data for ${
          employees.length
        } employees.
        ${
          count === undefined
            ? "Will be executed for count: 0 - 10"
            : `Will be executed for count: ${count}`
        }
      `
  )

  const addResults = async () => {
    for (let j = 0; j < employees.length; j++) {
      const employee = employees[j]
      setImpersonation(employee)

      const results = createResults(
        survey,
        fullyAgree,
        agree,
        disagree,
        fullyDisagree,
        abstain,
        comment
      )

      const publishRes: Result<null, MutationError> = await publishResults(
        survey.id,
        results,
        client
      )

      if (publishRes.ok) {
        console.log("Ok")
      }
    }
  }

  if (count === undefined) {
    // For Count 0 - 10
    for (let i = 0; i < 10; i++) {
      setCount(i)
      await addResults()
    }
  } else {
    // For a specific count given to the function
    setCount(count)
    await addResults()
  }
}
