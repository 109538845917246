export interface Choice {
  id: string
  text: string
}

export type QCardProps =
  | { type: "text"; value: string | null }
  | { type: "slider"; value: number; remark: string | null }
  | {
      type: "multipleChoice"
      choices: Choice[]
      value: string[]
      remark: string | null
    }
  | {
      type: "singleChoice"
      choices: Choice[]
      value: string | null
      remark: string | null
    }

export class QCard {
  abstained: boolean
  readonly props: QCardProps
  readonly question: string
  readonly questionId: string
  private _answerIsMissing: boolean

  constructor(
    question: string,
    props: QCardProps,
    id: string,
    abstained: boolean
  ) {
    this.question = question
    this.props = props
    this._answerIsMissing = false
    this.questionId = id
    this.abstained = abstained
  }

  get answerIsMissing(): boolean {
    return this._answerIsMissing
  }

  //returns true if required is true and there is no answer to the question
  isMissing(): boolean {
    if (this.abstained) {
      this._answerIsMissing = false
    } else {
      switch (this.props.type) {
        case "slider":
          this._answerIsMissing =
            this.props.value == null || this.props.value == 2 // 2 = middle value i.E no answer
          break
        case "multipleChoice":
          this._answerIsMissing =
            this.props.value == null || this.props.value.length < 1
          break
        case "singleChoice":
          this._answerIsMissing =
            this.props.value == null || this.props.value.trim().length == 0
          break
        case "text":
          this._answerIsMissing = false
          this.abstained = true
          break
        default: {
          throw "unknown qType"
        }
      }
      return this._answerIsMissing
    }
    return this._answerIsMissing
  }
}
