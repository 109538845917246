import { useApolloClient } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import {
  Survey,
  SurveyAnswerStatus,
  useCanTakeSurveyQuery,
  useGetSurveyQuery
} from "../api/generated"
import { MutationError, publishResults } from "../api/mutations"
import { Box, QuestionCard } from "../components/index"
import { Config } from "../config"
import { QForm, generateForm, getResults } from "../scripts/QForm"
import { Result, sortSurvey } from "../scripts/utils"
import { Error, FeedbackSent, Loading } from "./index"

import { ReactComponent as ChevronLeft } from "../assets/icons/chevron-left.svg"
import { ReactComponent as ChevronRight } from "../assets/icons/chevron-right.svg"

type submitState =
  | { state: "answering" }
  | { state: "submitPressed" }
  | { state: "submitted" }
  | { state: "error"; errCode: string }

interface navButton {
  content: string
  isShown: boolean
  func: () => void
  key: string
}

const SurveySection = () => {
  const [form, setForm] = useState<QForm>()
  const [submitted, setSubmitted] = useState<submitState>({
    state: "answering"
  })
  const [currentPage, setCurrentPage] = useState(0)
  const [noneMissing, setNoneMissing] = useState(false)
  const surveyId = useParams().surveyId ?? Config.wfsId
  const { t } = useTranslation()
  const client = useApolloClient()

  const canTakeSurveyResult = useCanTakeSurveyQuery({
    variables: {
      id: surveyId
    }
  })

  const { data, error, loading } = useGetSurveyQuery({
    variables: {
      id: surveyId
    },
    skip:
      canTakeSurveyResult.data?.canTakeSurvey != SurveyAnswerStatus.CanTake ||
      canTakeSurveyResult.loading
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])

  //generate form from survey when the query is finished
  useEffect(() => {
    if (data) {
      setForm(generateForm(sortSurvey(data.getSurvey as Survey)))
    }
  }, [data])

  useEffect(() => {
    //check if any fields are missing every time the form/current page changes
    form &&
      setNoneMissing(
        form[currentPage].qCards.reduce((acc: boolean, card) => {
          return acc && !card.isMissing()
        }, true)
      )
  }, [form, currentPage])

  const submitFunc = async () => {
    setSubmitted({ state: "submitPressed" })
    const publishRes: Result<null, MutationError> = await publishResults(
      surveyId,
      getResults(form as QForm),
      client
    )

    if (publishRes.ok) {
      setSubmitted({ state: "submitted" })
    } else {
      switch (publishRes.error.type) {
        case "addResultsError": {
          setSubmitted({
            state: "error",
            errCode:
              publishRes.error.message || "not all results were were created"
          })
          break
        }
        case "apolloError": {
          setSubmitted({
            state: "error",
            errCode: publishRes.error.value.message
          })
          break
        }
      }
    }
  }

  if (
    loading ||
    submitted.state == "submitPressed" ||
    canTakeSurveyResult.loading
  )
    return <Loading />
  if (canTakeSurveyResult.error)
    return <Error errorCode={canTakeSurveyResult.error.message} />
  if (canTakeSurveyResult.data?.canTakeSurvey != SurveyAnswerStatus.CanTake) {
    switch (canTakeSurveyResult.data?.canTakeSurvey as SurveyAnswerStatus) {
      case SurveyAnswerStatus.AlreadyTaken: {
        return (
          <div className="flex h-full w-full items-center justify-center text-center align-middle">
            <p> {t("survey.taken")}</p>
          </div>
        )
      }
      case SurveyAnswerStatus.SurveyNotRunning: {
        return (
          <div className="flex h-full w-full items-center justify-center text-center align-middle">
            <p> {t("survey.notRunning")}</p>
          </div>
        )
      }
      default:
        break
    }
  }
  if (error) return <Error errorCode={error.message} />
  if (submitted.state == "error") return <Error errorCode={submitted.errCode} />
  if (submitted.state == "submitted") return <FeedbackSent />
  if (!form) return <Error errorCode={"404"} />

  const navButtons: { prev: navButton; next: navButton; submit: navButton } = {
    prev: {
      content: t("nav.prev"),
      isShown: currentPage > 0,
      func: () => {
        if (!navButtons.prev.isShown) {
          return
        }
        setCurrentPage(currentPage - 1)
      },
      key: "prev"
    },
    next: {
      content: t("nav.next"),
      isShown: noneMissing && currentPage < form.length - 1,
      func: () => {
        if (!navButtons.next.isShown) {
          return
        }
        setCurrentPage(currentPage + 1)
      },
      key: "next"
    },
    submit: {
      content: t("submit"),
      isShown: noneMissing && currentPage == form.length - 1,
      func: () => {
        if (!navButtons.submit.isShown) {
          return
        }
        void submitFunc()
      },
      key: "submit"
    }
  }

  return (
    <>
      <Box>
        <div className="flex w-full flex-col">
          {form[currentPage].qCards.map((card, index) => (
            <QuestionCard
              key={card.questionId}
              card={card}
              setQCard={newCard => {
                //shallow copy cards and pages to prevent mutation of state
                const newCards = [...form[currentPage].qCards]
                newCards[index] = newCard
                const newForm = [...form]
                newForm[currentPage] = {
                  id: form[currentPage].id,
                  heading: form[currentPage].heading,
                  qCards: newCards
                }
                setForm(newForm)
              }}
            />
          ))}
        </div>
        {navButtons.submit.isShown && (
          <>
            <button
              className={
                "flex self-center rounded-md border-2 border-black px-5 py-1"
              }
              onClick={navButtons.submit.func}
            >
              {navButtons.submit.content}
            </button>
          </>
        )}
        <div className="mt-4 flex self-center">
          {!noneMissing && (
            <button className="font-bold text-exxeta-signal-red">
              {t("reqFields")}
            </button>
          )}
        </div>
        <div className="align-center mt-2 grid grid-cols-5 gap-6">
          <div
            onClick={navButtons.prev.func}
            className={`align-center col-span-2 flex justify-center space-x-2 self-center text-exxeta-gray-5 ${
              navButtons.prev.isShown
                ? "cursor-pointer"
                : "cursor-default select-none text-transparent"
            }`}
          >
            <ChevronLeft
              width={"1rem"}
              height={"1rem"}
              className={"flex self-center"}
            />
            {navButtons.prev.content}
          </div>
          <div className="align-center col-span-1 mt-1 flex flex-col text-center text-exxeta-gray-5">
            <span className="flex self-center">{t("nav.page")}</span>
            <span className="flex self-center">
              {currentPage + 1} / {form.length}
            </span>
          </div>
          <div
            onClick={navButtons.next.func}
            className={`align-center col-span-2 flex justify-center space-x-2 self-center text-exxeta-gray-5 ${
              navButtons.next.isShown
                ? "cursor-pointer"
                : "cursor-default select-none text-transparent"
            }`}
          >
            {navButtons.next.content}
            <ChevronRight
              width={"1rem"}
              height={"1rem"}
              className={"flex self-center"}
            />
          </div>
        </div>
      </Box>
    </>
  )
}

export default SurveySection
