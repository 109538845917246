import { useMsal } from "@azure/msal-react"
import { useGetOrganisationQuery, Employee, Survey } from "../api/generated"
import { useAppDispatch } from "../app/hooks"
import { Config, impersonation } from "../config"
import { setOrganizationState } from "../features/filter/organziationSlice"
import { useRef, useState } from "react"
import {
  useGetSurveyCountQuery,
  useGetSurveyAndResultsQuery
} from "../api/generated"
import { setCount, setSurveyState } from "../features/filter/surveySlice"

interface Props {
  onLoading: (bool: boolean) => void
}

const InitQuery = ({ onLoading }: Props) => {
  const { instance, accounts } = useMsal()
  const user = instance.getActiveAccount() || accounts[0]
  const [count, setStateCount] = useState(-1)
  const isOrgFetched = useRef(false)
  const dispatch = useAppDispatch()

  useGetOrganisationQuery({
    variables: {
      rootID: Config.isDev && impersonation ? impersonation : user.username
    },
    fetchPolicy: "cache-first",
    onCompleted: data => {
      isOrgFetched.current = true
      dispatch(setOrganizationState(data.getOrganisation as Employee))
    },
    skip: isOrgFetched.current
  })

  const { error: cError } = useGetSurveyCountQuery({
    variables: {
      id: Config.wfsId
    },
    onCompleted: data => {
      if (data.getSurvey) {
        const { previousOccurrence, occurrenceCount } = data.getSurvey

        setStateCount(previousOccurrence ?? occurrenceCount)
        dispatch(setCount(previousOccurrence ?? occurrenceCount))
      }
    },
    skip: count !== -1
  })

  const { error: sError } = useGetSurveyAndResultsQuery({
    variables: {
      count: count,
      id: Config.wfsId
    },
    onCompleted: data => {
      data.getSurvey && dispatch(setSurveyState(data.getSurvey as Survey))
      onLoading(false)
    },
    skip: count === -1
  })

  if (cError || sError)
    console.error(
      "error during inital query: ",
      cError?.message ?? sError?.message
    )

  return null
}

export default InitQuery
