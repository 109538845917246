import React from "react"
import { useTranslation } from "react-i18next"

const Error = (props: { errorCode: string }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-grow flex-row items-center justify-between text-center">
      <img
        className="m-5 h-10 md:h-20 lg:h-40"
        src="images/exxeta_HundehaufenSchild_positiv.png"
        alt=""
      />

      <div>
        <h1 className="whitespace-pre-line font-band1s-bold text-sm md:text-3xl">
          {t("error")}
        </h1>
        <h2 className="m-4 font-band1s-bold text-xs md:text-xl">
          error code: {props.errorCode}
        </h2>
        <p className="text-xs md:text-base">{t("tryLater")}</p>
      </div>
      <img
        className="m-5 h-10 md:h-20 lg:h-40"
        src="images/exxeta_HundehaufenSchild_positiv.png"
        alt=""
      />
    </div>
  )
}

export default Error
