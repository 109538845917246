import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Survey } from "../../api/generated"
import { getDateFromCount } from "../../scripts/utils"

interface SurveyDatesStore {
  count: number
  dates: string[] // past survey starting dates
  range?: [number, number] //for custom range in Details
  survey: Survey
}

const initialState: SurveyDatesStore = {
  count: -1,
  dates: [],
  survey: {} as Survey
}

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setSurveyState: (state, action: PayloadAction<Survey>) => {
      const { firstStart, startDays } = action.payload
      const count = state.count
      state.count = count
      const dates: string[] = []
      for (let i = 0; i <= count; i++) {
        dates.push(getDateFromCount(startDays, firstStart, i).toDateString())
      }
      state.dates = [...dates]
      state.survey = action.payload
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload
    },
    setRange: (state, action: PayloadAction<[number, number]>) => {
      state.range = action.payload
    },
    setSurvey: (state, action: PayloadAction<Survey>) => {
      state.survey = action.payload
    }
  }
})

export const { setSurveyState, setRange, setCount, setSurvey } =
  surveySlice.actions

export default surveySlice.reducer
