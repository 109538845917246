import React from "react"

import { Choice } from "../../scripts/QCard"

export interface MultipleChoiceCardProps {
  currentChoices: string[] //selected ids
  setCurrentChoices(newChoices: string[]): void
  choices: Choice[]
}

const MultipleChoiceCard = (props: MultipleChoiceCardProps) => {
  return (
    <div className="flex flex-wrap">
      {props.choices.map(choice => (
        <label
          key={choice.id + "label"}
          className="mr-12 flex flex-row items-center align-middle first-line:mr-0"
        >
          <input
            className="mr-2 h-4 w-4"
            type="checkbox"
            checked={!!props.currentChoices.find(elem => elem == choice.id)}
            key={choice.id}
            onChange={() => {
              if (props.currentChoices.find(elem => elem == choice.id)) {
                props.setCurrentChoices(
                  props.currentChoices.filter(elem => elem != choice.id)
                )
              } else {
                props.setCurrentChoices([...props.currentChoices, choice.id])
              }
            }}
          />
          {choice.text}
        </label>
      ))}
    </div>
  )
}

export default MultipleChoiceCard
