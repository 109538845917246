import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { HashRouter } from "react-router-dom"

import App from "./App"
import { store } from "./app/store"
import { ScrollToTop } from "./components"
import { Config } from "./config"
import "./i18n"
import "./index.css"

const msalAppObj: PublicClientApplication = new PublicClientApplication(
  Config.msalConfig
)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalAppObj}>
        <HashRouter>
          <ScrollToTop />
          <App />
        </HashRouter>
      </MsalProvider>
    </Provider>
  </React.StrictMode>
)
