import {
  AccountInfo,
  IPublicClientApplication,
  InteractionRequiredAuthError
} from "@azure/msal-browser"

import { Config } from "../config"

export const acquireToken = async (
  instance: IPublicClientApplication,
  account: AccountInfo
): Promise<string> => {
  try {
    const res = await instance.acquireTokenSilent({
      scopes: [...Config.msalScopes],
      account: account
    })
    return res.accessToken
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      void instance
      const res = await instance.acquireTokenPopup({
        scopes: [...Config.msalScopes],
        account: account
      })
      return res.accessToken
    } else {
      throw e
    }
  }
}
