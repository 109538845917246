import type {
  AggregatedPageResults,
  AggregatedQuestionResults,
  AggregatedSingleChoiceResults,
  AggregatedSliderResult,
  Employee,
  GetQuestionResultsQuery,
  MultipleChoiceAnswer,
  Page,
  Question,
  ResultAnswer,
  SingleChoiceAnswer,
  SliderAnswer,
  Survey
} from "../api/generated"
import { QuestionType } from "../api/generated"
import type { BarChartProps, BarData } from "../components"
import { BarChart, MiniStackedBarChart } from "../components"
import { Amounts } from "../components/charts/MiniStackedBarChart"
import i18n from "../i18n"
import React from "react"
import { addYears, format, fromUnixTime } from "date-fns"
import { TreeData } from "../components/TreeSelect"
import { PercentageIndicatorProps } from "../components/charts/PercentageIndicator"
import { SelectedManager } from "../features/filter/organziationSlice"
import i18next from "i18next"

export type Result<T, E> = { ok: true; value: T } | { ok: false; error: E }
export type QuestionResults = GetQuestionResultsQuery["getResultsByQuestion"]

export const getDateFromCount = (
  startDates: string[],
  firstStart: number,
  count: number
) => {
  const startDateIndex = count % startDates.length
  const surveyFirstStart = fromUnixTime(firstStart)
  const splitStartDates: Array<Array<number>> = startDates.map(startDate => {
    return startDate.split(" ").map(Number) // [day, month]
  })

  // Get in which index the surveyFirstStart is
  const startMonthIndex = splitStartDates.findIndex(([, month]) => {
    return month - 1 === surveyFirstStart.getMonth()
  })

  // Offset the startDateIndex by that value
  const offsetStartIndex =
    (startDateIndex + startMonthIndex) % startDates.length

  // Offset year, if we are in first month and startDateIndex is not equal to startMonthIndex
  // Needs to be done, because the firstStart date can be the 2nd index in the array of startDates
  // The first is supposed to be in the next year at that point.
  const yearOffset =
    offsetStartIndex === 0 && startDateIndex !== startMonthIndex ? 1 : 0

  //cycle represents the number the survey cycled already through startDates
  const cycle = Math.floor(count / startDates.length)
  // Add the offset to the year
  // Should be 0 instead the firstStart is not the first element in the array
  const year = addYears(surveyFirstStart, cycle).getFullYear() + yearOffset
  const [day, month] = splitStartDates[offsetStartIndex]
  // We need to offset the month because monthIndex starts at 0
  return new Date(year, month - 1, day)
}

export const getPeriod = (date: Date): string => format(date, "QQQ yyyy")

const getFirstFourEntries = <T,>(sortedMap: Map<string, T>): Map<string, T> => {
  const entries = Array.from(sortedMap.entries())
  const lastFourEntries = entries.slice(0, 4)
  return new Map<string, T>(lastFourEntries)
}

export const getDetailedChart = (
  firstStart: number,
  startDates: string[],
  questionResults: QuestionResults,
  qType: QuestionType,
  heading: string
) => {
  if (!questionResults.length || questionResults.length == 0)
    return <p>sorry, no data found</p>

  const sortedQuestionResults = questionResults.sort(
    (a, b) => b.count - a.count
  )

  const allResults: Map<
    string,
    Array<(typeof questionResults)[0]["answer"]>
  > = sortedQuestionResults.reduce((acc, curr) => {
    const date = getDateFromCount(startDates, firstStart, curr.count)
    const dateStr = format(date, "QQQ ''yy")
    acc.get(dateStr)?.push(curr.answer) ?? acc.set(dateStr, [curr.answer])
    return acc
  }, new Map<string, Array<(typeof questionResults)[0]["answer"]>>())

  const results = getFirstFourEntries(allResults)

  switch (qType) {
    case QuestionType.Slider: {
      const labels = [...results.keys()]
      const answers = [...results.values()]
      const data = answers.map((answer, i): BarData => {
        const sliderAns = answer.filter(
          el => el.__typename === "SliderAnswer"
        ) as SliderAnswer[]
        const abstained = answer.length - sliderAns.length
        const amount = sliderAns.reduce(
          (prev, curr): Amounts => {
            const val = curr.slider
            if (val == 0 || val == 1) {
              prev[val]--
              return prev
            } else {
              prev[val - 1]++
              return prev
            }
          },
          [0, 0, 0, 0] as Amounts
        )

        return {
          label: labels[i],
          amount: amount,
          abstained: abstained,
          isEmpty: amount.every(el => el === 0) && abstained === 0
        }
      })
      return <MiniStackedBarChart barData={data} />
    }
    case QuestionType.SingleChoice: {
      const periods = [...results.keys()]
      const allPeriodResults = [...results.values()] as SingleChoiceAnswer[][]
      const choices = [
        ...new Set(
          allPeriodResults
            .flat()
            .map(answer => i18n.t(`wfs:answer.${answer.AnswerID}`))
        )
      ]

      // Map<choices, values>[], every Map represents the results of one period
      const resultsPerPeriod = allPeriodResults.map(
        answerArr =>
          new Map(
            choices.map(choice => [
              choice,
              answerArr.filter(
                answer => i18n.t(`wfs:answer.${answer.AnswerID}`) === choice
              ).length
            ])
          )
      )

      //[[percentages of choices of period 1],[percentages of choices of period 2],...]
      const percentPerPeriod = resultsPerPeriod.map(results => {
        const values = [...results.values()]
        const totalResponses = values.reduce((acc, curr) => acc + curr, 0)
        return values.map(val => (val / totalResponses) * 100)
      })

      //[[percentages of choice 1 of all periods],[percentages of choice 2 of all periods],...]
      const sortedValues = choices.map((_, i) =>
        percentPerPeriod.reduce((acc, curr) => [...acc, curr[i]], [])
      )

      const result: BarChartProps = {
        isSingleChoice: true,
        questionTitle: heading,
        choices,
        periods,
        dataArr: sortedValues
      }

      return <BarChart {...result} />
    }
    case QuestionType.MultipleChoice: {
      const periods = [...results.keys()]
      const allPeriodResults = [...results.values()] as MultipleChoiceAnswer[][]

      const choices = [
        ...new Set(
          allPeriodResults
            .flat()
            .map(answer =>
              answer.AnswerIDs.map(id => i18n.t(`wfs:answer.${id}`))
            )
            .flat()
        )
      ]

      // Map<choices, values>[], every Map represents the results of one period
      const resultsPerPeriod = allPeriodResults.map(
        answerArr =>
          new Map(
            choices.map(choice => [
              choice,
              answerArr.reduce(
                (acc, curr) =>
                  acc +
                  curr.AnswerIDs.filter(
                    id => i18n.t(`wfs:answer.${id}`) == choice
                  ).length,
                0
              )
            ])
          )
      )

      //[[percentages of choices(!) of period 1],[percentages of choices of period 2],...]
      const percentPerPeriod = resultsPerPeriod.map((results, i) => {
        const responses = [...results.values()]
        const totalResponses = allPeriodResults[i].length
        return responses.map(res => (res / totalResponses) * 100)
      })

      //[[percentages of choice 1 of all periods],[percentages of choice 2 of all periods],...]
      const sortedValues = choices.map((_, i) =>
        percentPerPeriod.reduce((acc, curr) => [...acc, curr[i]], [])
      )

      const result: BarChartProps = {
        isSingleChoice: false,
        questionTitle: heading,
        choices,
        periods,
        dataArr: sortedValues
      }

      return <BarChart {...result} />
    }
    case QuestionType.Text: {
      return <></>
    }
  }
}

export const formatAmount = (num: number): string => {
  if (num < 1000) {
    return num.toString()
  } else {
    const abbreviated = Math.floor(num / 1000)
    const remainder = num % 1000
    const decimal = remainder ? `.${Math.floor(remainder / 100)}` : ""
    return `${abbreviated}${decimal}`
  }
}

// getText extracts the answer(s) and remark as text from the given ResultAnswer
// requires the __typename field of the ResultAnswer to exist
export const getTextFromResultAnswer = (
  answer: ResultAnswer
): [string, string | undefined | null] => {
  switch (answer.__typename) {
    case "SingleChoiceAnswer":
      return [i18n.t(`wfs:answer.${answer.AnswerID}`), answer.remark]
    case "MultipleChoiceAnswer":
      return [
        answer.AnswerIDs.reduce((acc, id, index, src) => {
          index === src.length - 1
            ? (acc += i18n.t(`wfs:answer.${id}`))
            : (acc += `${i18n.t(`wfs:answer.${id}`)}, `)
          return acc
        }, ""),
        answer.remark
      ]
    case "SliderAnswer":
      // eslint-disable-next-line no-case-declarations
      let answerText: string
      switch (answer.slider) {
        case 0:
          answerText = i18n.t("slider.disagree")
          break
        case 1:
          answerText = i18n.t("slider.partDisagree")
          break
        case 3:
          answerText = i18n.t("slider.partAgree")
          break
        case 4:
          answerText = i18n.t("slider.agree")
          break
      }
      return [answerText, answer.remark]
    case "TextAnswer":
      return [answer.text, null]
    case "AbstainedAnswer":
      return [i18n.t("abstain"), answer.remark]
    case undefined: {
      console.error(
        "could not get Text from ResultAnswer, __typename undefined"
      )
      return ["", null]
    }
  }
}

// sortSurvey clones and sorts the given Survey
export const sortSurvey = (survey: Survey): Survey => {
  const retVal = structuredClone(survey) as Survey

  retVal.pages = survey.pages.map(page => {
    const newPage = { ...page }
    newPage.questions = [...page.questions]
    return newPage
  })
  return retVal
}

export const roundToOneDecimal = (i: number) => Math.round(i * 10) / 10

export const aggregatePageResults = (
  results: AggregatedPageResults[],
  isText?: boolean
) => {
  if (!isText) {
    const sliderInput: AggregatesInput[] = results.map(res => ({
      abstained: res.abstained,
      amount: res.amount,
      prevApproval: res.prevApproval ?? undefined,
      prevAmount: res.prevAmount ?? undefined,
      results: res.res
    }))

    return processAgreggatedSliderResults(sliderInput)
  } else {
    return processDefaultPageResults(results)
  }
}

export const processDefaultPageResults = (
  results: AggregatedPageResults[]
): PercentageIndicatorProps =>
  results.reduce(
    (acc, curr) => ({
      amount: acc.amount + curr.amount,
      abstained: acc.abstained + curr.abstained,
      isText: true
    }),
    { amount: 0, abstained: 0, isText: true }
  )

export const weightedAvg = (
  [val1, weight1]: [number, number],
  [val2, weight2]: [number, number]
) =>
  (val1 * weight1) / (weight1 + weight2) +
  (val2 * weight2) / (weight1 + weight2)

//logic shared between Category and PageResults
export type AggregatesInput = {
  prevApproval?: number
  prevAmount?: number
  amount: number
  abstained: number
  results: AggregatedSliderResult[]
}

export const processAgreggatedSliderResults = (
  input: AggregatesInput[]
): PercentageIndicatorProps => {
  let prevApproval = undefined
  let prevAmount = 0
  let amount = 0
  let abstained = 0
  let approvalAmount = 0

  for (const res of input) {
    for (const answer of res.results) {
      if (answer.value > 2) {
        approvalAmount += answer.answerAmount
      }

      if (res.prevApproval === null || res.prevApproval === undefined) {
        continue
      }

      if (prevApproval !== undefined) {
        prevApproval = weightedAvg(
          [prevApproval, prevAmount],
          [res.prevApproval, res.prevAmount ?? 0]
        )
        prevAmount += res.prevAmount ?? 0
        continue
      }

      prevApproval = res.prevApproval
    }
    amount += res.amount - res.abstained
    abstained += res.abstained
  }

  const approval = amount === 0 ? undefined : (approvalAmount / amount) * 100

  const change = approval ? approval - (prevApproval ?? 0) : undefined

  return {
    abstained,
    amount,
    change,
    approval
  }
}

export const getAllSubordinates = (root: Employee): Employee[] => {
  const subordinates = []
  for (const subordinate of root.subordinates) {
    subordinates.push(subordinate)
    subordinates.push(...getAllSubordinates(subordinate))
  }
  return subordinates
}

// getManagerTree removes all Employees who are not managers from the given Employee tree
export const getManagerTree = (employee: Employee): Employee => {
  const { id, name, subordinates } = employee
  const filteredSubordinates = subordinates
    .filter(
      subordinate =>
        subordinate.subordinates && subordinate.subordinates.length > 0
    )
    .map(getManagerTree)
  return { id, name, subordinates: filteredSubordinates }
}

export const convertToTreeData = (employee: Employee): TreeData => {
  const treeData: TreeData = {
    ...employee,
    isExtended: false,
    canBeExtended: employee.subordinates.length > 0,
    subordinates: []
  }

  if (employee.subordinates.length > 0) {
    treeData.subordinates = [...employee.subordinates]
      .sort((a, b) => a.subordinates.length - b.subordinates.length)
      .map(subordinate => convertToTreeData(subordinate))
  }

  return treeData
}

export const searchAndEditTree = (
  rootEmployee: TreeData,
  searchedEmployee: TreeData,
  extend?: boolean
): TreeData => {
  if (rootEmployee.id === searchedEmployee.id) {
    return {
      ...rootEmployee,
      isExtended:
        extend && searchedEmployee.canBeExtended
          ? !searchedEmployee.isExtended
          : searchedEmployee.isExtended
    }
  }

  if (rootEmployee.subordinates.length < 1) {
    return rootEmployee
  }

  const updatedSubordinates = rootEmployee.subordinates.map(subordinate =>
    searchAndEditTree(subordinate, searchedEmployee, extend)
  )

  return {
    ...rootEmployee,
    subordinates: updatedSubordinates
  }
}

export const getEmailName = (email: string): string => {
  const [name] = email.split("@")
  const [firstName, lastName] = name.split(".")
  return `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)} ${lastName
    .charAt(0)
    .toUpperCase()}${lastName.slice(1)}`
}

interface PageNavigationDetails {
  currPage?: Page
  prevPage?: Page
  nextPage?: Page
}

export const getPageNavigationDetails = (
  survey: Survey,
  pageId: string
): PageNavigationDetails => {
  const pageIndex = survey.pages.findIndex(page => page.id === pageId)

  if (pageIndex === -1) return {}
  else
    return {
      prevPage: survey.pages[pageIndex - 1],
      currPage: survey.pages[pageIndex],
      nextPage: survey.pages[pageIndex + 1]
    }
}

interface QuestionNavigationDetails {
  currQuestion?: Question
  prevQuestionId?: string
  nextQuestionId?: string
}

export const getQuestionNavigationDetails = (
  page: Page,
  questionId: string
): QuestionNavigationDetails => {
  const questionIndex = page.questions.findIndex(
    question => question.id === questionId
  )

  if (questionIndex === -1) return {}
  else
    return {
      prevQuestionId: page.questions[questionIndex - 1]?.id,
      currQuestion: page.questions[questionIndex],
      nextQuestionId: page.questions[questionIndex + 1]?.id
    }
}

export const processDirectPageResults = (
  pages: Page[],
  userId: string
): PercentageIndicatorProps[] =>
  pages.reduce((acc, page) => {
    const pageFilteredResults = page.aggregatedSliderResults.filter(
      res => res.managerId === userId
    )
    acc.push(
      aggregatePageResults(
        pageFilteredResults,
        page.questions[0].qType === QuestionType.Text
      )
    )
    return acc
  }, [] as PercentageIndicatorProps[])

export const processIndirectPageResults = (
  pages: Page[]
): PercentageIndicatorProps[] =>
  pages.map(page =>
    aggregatePageResults(
      page.aggregatedSliderResults,
      page.questions[0].qType === QuestionType.Text
    )
  )

export const processIndirectPageResultsForManager = (
  pages: Page[],
  selectedManager: SelectedManager
): PercentageIndicatorProps[] =>
  pages.reduce((acc, page) => {
    const pageFilteredResults = page.aggregatedSliderResults.filter(
      res =>
        selectedManager?.subManagers.includes(res.managerId) ||
        res.managerId === selectedManager?.id
    )
    acc.push(
      aggregatePageResults(
        pageFilteredResults,
        page.questions[0].qType === QuestionType.Text
      )
    )
    return acc
  }, [] as PercentageIndicatorProps[])

export const processIndirectQuestionResults = (
  questions: Question[]
): PercentageIndicatorProps[] =>
  questions.map(curr =>
    getPctIndicatorProps(
      curr.aggregatedResults,
      curr.qType === QuestionType.Text
    )
  )

export const processDirectQuestionResults = (
  questions: Question[],
  userId: string
): PercentageIndicatorProps[] =>
  questions.reduce((acc, curr) => {
    const filteredResults = curr.aggregatedResults.filter(
      res => res.managerId === userId
    )
    acc.push(
      getPctIndicatorProps(filteredResults, curr.qType === QuestionType.Text)
    )
    return acc
  }, [] as PercentageIndicatorProps[])

export const processDirectQuestionResultsForManager = (
  questions: Question[],
  selectedManager: SelectedManager
) =>
  questions.reduce((acc, curr) => {
    const filteredResults = curr.aggregatedResults.filter(
      res => res.managerId === selectedManager.id
    )
    acc.push(
      getPctIndicatorProps(filteredResults, curr.qType === QuestionType.Text)
    )
    return acc
  }, [] as PercentageIndicatorProps[])

export const processIndirectQuestionResultsForManager = (
  questions: Question[],
  selectedManager: SelectedManager
) =>
  questions.reduce((acc, curr) => {
    const filteredResults = curr.aggregatedResults.filter(
      res =>
        selectedManager?.subManagers.includes(res.managerId) ||
        res.managerId === selectedManager?.id
    )
    acc.push(
      getPctIndicatorProps(filteredResults, curr.qType === QuestionType.Text)
    )
    return acc
  }, [] as PercentageIndicatorProps[])

export const processDefaultQuestionResults = (
  results: AggregatedQuestionResults[]
): PercentageIndicatorProps =>
  results.reduce(
    (acc, curr) => ({
      amount: acc.amount + curr.amount,
      abstained: acc.abstained + curr.abstained,
      isText: true
    }),
    { amount: 0, abstained: 0, isText: true }
  )

export const processSliderQuestionResults = (
  results: AggregatedQuestionResults[]
) => {
  const input = results.reduce(
    (acc, curr) =>
      acc.concat({
        abstained: curr.abstained,
        amount: curr.amount,
        prevApproval: curr.prevApproval ?? undefined,
        prevAmount: curr.prevAmount ?? undefined,
        results: (curr.res?.results ?? []) as AggregatedSliderResult[]
      }),
    [] as AggregatesInput[]
  )
  return processAgreggatedSliderResults(input)
}

export const getPctIndicatorProps = (
  results: AggregatedQuestionResults[],
  isTextQuestion?: boolean
): PercentageIndicatorProps => {
  if (isTextQuestion) return processDefaultQuestionResults(results)
  else if (results[0]?.res?.__typename === "AggregatedSliderResults")
    return processSliderQuestionResults(results)
  else return processDefaultQuestionResults(results)
}

//unused, because there are no sc questions yet
export const processSingleChoiceQuestionResults = (
  results: AggregatedQuestionResults[],
  abstainedText: string
): { amount: number; values: Map<string, number> } => {
  let amount = 0
  const values = new Map<string, number>()

  for (const res of results) {
    const resVals = res.res as AggregatedSingleChoiceResults
    amount += res.amount
    values.set(abstainedText, (values.get(abstainedText) ?? 0) + res.abstained)
    for (const val of resVals.results) {
      const text = i18next.t(`wfs:answer.${val.answerId}`)
      values.set(text, (values.get(text) ?? 0) + val.answerAmount)
    }
  }

  return {
    amount,
    values
  }
}
