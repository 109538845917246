import React from "react"
import { useTranslation } from "react-i18next"

import { Result } from "../api/generated"
import { getDateFromCount, getPeriod, QuestionResults } from "../scripts/utils"

interface ResultListProps {
  results: QuestionResults
  firstStart: number
  startDates: string[]
}

interface TextItem {
  value: string
  period: string
}
interface RemarkItem {
  value: string[] | string
  remark: string
  period: string
}

const SliderValues = {
  4: "agreed",
  3: "partAgreed",
  1: "partDisagreed",
  0: "disagreed"
} as const

const getNotEmptyTextAnswers = (
  results: Result[],
  firstStart: number,
  startDates: string[]
) =>
  results.reduce((acc, curr) => {
    if (curr.answer.__typename === "TextAnswer" && curr.answer.text)
      acc.push({
        value: curr.answer.text,
        period: getPeriod(getDateFromCount(startDates, firstStart, curr.count))
      })
    return acc
  }, [] as TextItem[])

const getResultsWithRemarks = (
  results: Result[],
  firstStart: number,
  startDates: string[]
) =>
  results.reduce((acc, curr) => {
    if ("remark" in curr.answer && curr.answer.remark)
      switch (curr.answer.__typename) {
        case "AbstainedAnswer":
          acc.push({
            value: "abstained",
            period: getPeriod(
              getDateFromCount(startDates, firstStart, curr.count)
            ),
            remark: curr.answer.remark
          })
          return acc
        case "SliderAnswer":
          acc.push({
            value: `slider.${SliderValues[curr.answer.slider]}`,
            period: getPeriod(
              getDateFromCount(startDates, firstStart, curr.count)
            ),
            remark: curr.answer.remark
          })
          return acc
        case "SingleChoiceAnswer":
          acc.push({
            value: curr.answer.AnswerID,
            period: getPeriod(
              getDateFromCount(startDates, firstStart, curr.count)
            ),
            remark: curr.answer.remark
          })
          return acc
        case "MultipleChoiceAnswer":
          acc.push({
            value: curr.answer.AnswerIDs,
            period: getPeriod(
              getDateFromCount(startDates, firstStart, curr.count)
            ),
            remark: curr.answer.remark
          })
          return acc
        default:
          return acc
      }
    return acc
  }, [] as RemarkItem[])

const isRemarkList = (list: TextItem[] | RemarkItem[]): list is RemarkItem[] =>
  (list[0] as RemarkItem).remark !== undefined

//contains only results with remarks or not empty Textanswers
const ResultList = ({
  results: allResults,
  firstStart,
  startDates
}: ResultListProps) => {
  const { t } = useTranslation()

  if (allResults.length === 0) return null

  const results: TextItem[] | RemarkItem[] =
    allResults[0].answer.__typename === "TextAnswer"
      ? getNotEmptyTextAnswers(allResults as Result[], firstStart, startDates)
      : getResultsWithRemarks(allResults as Result[], firstStart, startDates)

  const List = () =>
    results.length > 0 && isRemarkList(results) ? (
      <ul className="max flex  max-h-64 flex-col  overflow-y-auto scroll-smooth border-black scrollbar-thumb-exxeta-gray-3 sm:border-b-2 sm:scrollbar-thin  sm-max:scrollbar-none">
        {results.map((item, i) => (
          <li
            className="flex flex-col border-b-2  border-exxeta-gray-3 last-of-type:border-none"
            key={i}
          >
            <div className="flex">{item.remark}</div>
            <div className="flex flex-row justify-end gap-1 px-1 text-sm font-semibold italic text-exxeta-gray-5">
              <span className="flex ">{t(item.value)},</span>
              <span className="flex">{item.period}</span>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <div className="flex flex-col">
        {results.map((item, i) => (
          <div
            className="flex flex-col border-b-2 border-exxeta-gray-4"
            key={i}
          >
            <div className="flex flex-row justify-end  font-semibold italic">
              <div className="flex">{t(item.value)}</div>
              <span className="flex">{item.period}</span>
            </div>
          </div>
        ))}
      </div>
    )

  return (
    <div className="mb-auto flex w-full flex-col pl-1 ">
      <div className="  collapse-arrow collapse   ">
        <input type="checkbox" defaultChecked />
        <div className="collapse-title  flex text-left text-xl">
          <p className="flex items-center justify-center">
            <span className="flex underline ">{t("remark_other")}</span>
            <span className="text-md ml-1 flex text-exxeta-gray-5">
              ({results.length})
            </span>
          </p>
        </div>
        <div className="collapse-content">
          <List />
        </div>
      </div>
    </div>
  )
}

export default ResultList
