import React from "react"
import { useTranslation } from "react-i18next"

export interface SliderCardProps {
  setValue(newVal: number): void
  name: string
  value: number
}

const CheckValue = {
  agree: 4,
  partAgree: 3,
  partDisagree: 1,
  disagree: 0
} as const

const SliderCard = ({ setValue, name, value }: SliderCardProps) => {
  const { t } = useTranslation()
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(+e.target.value)
  }

  return (
    <div className="flex flex-col items-start justify-center pl-1">
      <div className="flex flex-col items-start justify-center gap-2">
        <div className="flex justify-evenly gap-2">
          <input
            type="radio"
            id={`agree${name}`}
            value={4}
            name={name}
            className="radio"
            onChange={onChange}
            checked={CheckValue.agree === value}
          />
          <label htmlFor={`agree${name}`}> {t("slider.agree")}</label>
        </div>
        <div className="flex gap-2">
          <input
            type="radio"
            id={`partAgree${name}`}
            value={3}
            name={name}
            className="radio"
            onChange={onChange}
            checked={CheckValue.partAgree === value}
          />
          <label htmlFor={`partAgree${name}`}>{t("slider.partAgree")}</label>
        </div>
        <div className="flex gap-2">
          <input
            type="radio"
            id={`partDisagree${name}`}
            value={1}
            name={name}
            className="radio"
            onChange={onChange}
            checked={CheckValue.partDisagree === value}
          />
          <label htmlFor={`partDisagree${name}`}>
            {t("slider.partDisagree")}
          </label>
        </div>
        <div className="flex gap-2">
          <input
            type="radio"
            id={`disagree${name}`}
            value={0}
            name={name}
            className="radio"
            onChange={onChange}
            checked={CheckValue.disagree === value}
          />
          <label htmlFor={`disagree${name}`}>{t("slider.disagree")}</label>
        </div>
      </div>
    </div>
  )
}

export default SliderCard
