import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { MultipleChoiceCard, SingleChoiceCard, SliderCard, TextCard } from "../"
import { QCard } from "../../scripts/QCard"
import { QuestionType } from "../../api/generated"

interface CommentBoxProps {
  abstained: boolean
  onAbstained: (card: QCard) => void
  onText: (str: string) => void
  card: QCard
  disabled: boolean | undefined
  comment: string | null
}

const CommentBox = ({
  abstained,
  onAbstained,
  onText,
  card,
  disabled,
  comment
}: CommentBoxProps) => {
  const [text, setText] = useState(comment ?? "")
  const [isOpen, setIsOpen] = useState(!!comment)
  const { t } = useTranslation()
  return (
    <div className="flex flex-col">
      <div className="flex justify-around">
        <button
          className="flex text-lg text-exxeta-gray-5"
          onClick={() => setIsOpen(!isOpen)}
        >
          {t("comment")}
        </button>
        <div
          className={`flex flex-row-reverse gap-2 text-lg ${
            !abstained ? "text-exxeta-gray-5" : "text-black"
          }`}
        >
          <label htmlFor="abstained" className="flex  ">
            {t("abstain")}
          </label>
          <input
            type="checkbox"
            name="abstained"
            className={`checkbox flex self-center ${
              !disabled ? "" : "disabled"
            } `}
            onChange={() => onAbstained(card)}
            checked={abstained}
          />
        </div>
      </div>
      {isOpen && (
        <textarea
          name="comment"
          id="comment"
          maxLength={200}
          value={text}
          onChange={e => {
            setText(e.target.value)
            onText(e.target.value)
          }}
          placeholder={t("commentPlaceholder") || ""}
          rows={3}
          className="mr-6 ml-6 mt-2 mb-2 flex border-t-2 border-b-2 p-2"
        />
      )}
    </div>
  )
}

export interface QuestionCardProps {
  card: QCard
  abstainDisabled?: boolean
  isFeedback?: boolean
  setQCard(newQCard: QCard): void
}

const QuestionCard = ({
  card,
  abstainDisabled,
  setQCard,
  isFeedback = false
}: QuestionCardProps) => {
  const [abstained, setAbstained] = useState(card.abstained)
  const { t } = useTranslation("wfs")

  const onAbstained = (card: QCard) => {
    setAbstained(prev => !prev)
    setQCard(
      new QCard(card.question, card.props, card.questionId, !card.abstained)
    )
  }

  //helper function to reduce boilerplate
  const setVal = <T extends typeof card.props.value>(
    newVal: T,
    hasNoText?: boolean
  ) => {
    const newCard = new QCard(
      card.question,
      card.props,
      card.questionId,
      hasNoText !== undefined ? hasNoText : abstained
    )

    newCard.props.value = newVal
    setQCard(newCard)
  }

  const onRemark = (newVal: string) => {
    const newCard = new QCard(
      card.question,
      card.props,
      card.questionId,
      card.abstained
    )

    if (newCard.props.type !== "text") {
      newCard.props.remark = newVal
    }
    setQCard(newCard)
  }

  const CardElement =
    card.props.type === "text" ? (
      <TextCard value={card.props.value} setValue={setVal} charLimit={200} />
    ) : card.props.type === "slider" ? (
      <SliderCard
        setValue={setVal}
        name={card.questionId}
        value={card.props.value}
      />
    ) : card.props.type === "singleChoice" ? (
      <SingleChoiceCard
        currentChoice={card.props.value}
        setCurrentChoice={setVal}
        choices={card.props.choices}
      />
    ) : card.props.type === "multipleChoice" ? (
      <MultipleChoiceCard
        currentChoices={card.props.value}
        setCurrentChoices={setVal}
        choices={card.props.choices}
      />
    ) : undefined

  return (
    <div
      className={`flex w-full flex-shrink-0 flex-col border-b-2  border-exxeta-gray-1  p-1 last:border-b-0 ${
        isFeedback ? " justify-around border-none" : ""
      }`}
    >
      <div
        className={` collapse  w-full ${
          abstained && !isFeedback && card.props.type !== QuestionType.Text
            ? "collapse-close"
            : "collapse-open"
        }`}
      >
        <div className="collapse-title p-1 pr-1 text-left">
          <h2
            className={`collapse-title  font-Sen-Bold text-lg ${
              (abstained || !card.isMissing()) && !isFeedback
                ? "text-exxeta-gray-4"
                : ""
            } `}
          >
            {!isFeedback ? t(`question.${card.questionId}`) : card.question}
            <sup className={"top-[-0.25rem] text-2xl  text-exxeta-signal-red"}>
              {card.isMissing() && !isFeedback ? "*" : ""}
            </sup>
          </h2>
        </div>
        <div className="collapse-content">{CardElement}</div>
      </div>

      {card.props.type !== "text" && (
        <CommentBox
          abstained={abstained}
          onAbstained={card => onAbstained(card)}
          onText={onRemark}
          card={card}
          disabled={abstainDisabled}
          comment={card.props.remark}
        />
      )}
    </div>
  )
}

export default QuestionCard
