import React from "react"
import { useTranslation } from "react-i18next"
import { ContactModal } from "./Modals"

const FooterBar = () => {
  const { t } = useTranslation()

  return (
    <footer className="mt-auto flex w-full flex-grow-0  items-center  justify-center gap-4  py-3 text-sm font-semibold uppercase text-exxeta-gray-4">
      <a
        href="https://exxeta.com/impressum"
        target="_blank"
        rel="noopener noreferrer"
        className="link-hover link flex"
      >
        {t("imprint")}
      </a>

      <label
        htmlFor="contactModal"
        className="flex cursor-pointer hover:underline"
      >
        {t("contact")}
      </label>

      <a
        href="https://forms.office.com/e/Ggsg35J3jz"
        target="_blank"
        rel="noopener noreferrer"
        className="link-hover link flex"
      >
        {t("bug report")}
      </a>

      <a
        href="https://gitlab.exxeta.com/feedbackhub/feedbackhub/"
        target="_blank"
        rel="noopener noreferrer"
        className="link-hover link flex"
      >
        Git
      </a>
      <ContactModal />
    </footer>
  )
}

export default FooterBar
