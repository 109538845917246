import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import {
  CanSeeResult,
  SurveyAnswerStatus,
  SurveyState,
  useCanSeeResultsQuery,
  useCanTakeSurveyQuery
} from "../api/generated"
import { ReactComponent as SupLogo } from "../assets/images/exxeta-superzeichen.svg"
import { Config } from "../config"
import { Error, Loading } from "./index"
import SurveyCountdown from "../components/SurveyCountdown"
import { useAppSelector } from "../app/hooks"

interface Link {
  route: string
  title: string
  isShown: boolean
}

const Home = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { survey } = useAppSelector(state => state.filters.survey)

  const canSeeResultsResult = useCanSeeResultsQuery({
    variables: {
      id: Config.wfsId
    },
    fetchPolicy: Config.isDev ? "no-cache" : "cache-first"
  })

  const canTakeSurveyResult = useCanTakeSurveyQuery({
    variables: {
      id: Config.wfsId
    },
    fetchPolicy: Config.isDev ? "no-cache" : "cache-first"
  })

  const links: Link[] = [
    {
      route: "feedback",
      title: "home.feedback",
      isShown: false // Feedback is disabled for now
    },
    {
      route: "wfs",
      title: "home.takeSurvey",
      isShown:
        canTakeSurveyResult.data?.canTakeSurvey == SurveyAnswerStatus.CanTake
    },
    {
      route: "results",
      title: "home.results",
      isShown:
        canSeeResultsResult.data?.canSeeResults == CanSeeResult.CanSee &&
        survey.pages.some(el => el.aggregatedSliderResults.length > 0) &&
        survey.occurrenceCount > 0
    },
    {
      route: "dev",
      title: "Tools",
      isShown: Config.isDev
    }
  ]

  if (canSeeResultsResult.loading || canTakeSurveyResult.loading) {
    return <Loading />
  }

  if (canSeeResultsResult.error) {
    return <Error errorCode={canSeeResultsResult.error.message} />
  }

  if (canTakeSurveyResult.error) {
    return <Error errorCode={canTakeSurveyResult.error.message} />
  }
  return (
    <div className="flex h-full w-full flex-grow  flex-col-reverse items-center justify-around   ">
      <div className=" flex w-screen  flex-col items-baseline justify-evenly gap-14   md:pl-0 ">
        {survey.state === SurveyState.Created && (
          <div
            className={"align-center mt-4 flex w-full flex-col justify-center"}
          >
            <SurveyCountdown
              startDates={survey.startDays}
              firstStart={survey.firstStart}
              count={survey.occurrenceCount}
            ></SurveyCountdown>
          </div>
        )}
        {links
          .filter(link => link.isShown)
          .map(link => (
            <button
              key={link.route}
              onClick={() => navigate(`${link.route}`)}
              className="flex w-fit items-center justify-center self-center text-left  text-4xl hover:underline  md:text-center"
            >
              <p className="">{t(`${link.title}`)}</p>
            </button>
          ))}
      </div>
      <div className="flex pt-20 md:pt-10">
        <SupLogo className="flex h-12 w-fit " />
      </div>
    </div>
  )
}

export default Home
