import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DayMonth: string;
  SliderValue: 0 | 1 | 3 | 4;
  Uint: number;
  UnixTime: number;
};

export type AbstainedAnswer = {
  __typename?: 'AbstainedAnswer';
  remark?: Maybe<Scalars['String']>;
};

export type AbstainedResultIn = {
  remark?: InputMaybe<Scalars['String']>;
};

export type AggregatedPageResults = {
  __typename?: 'AggregatedPageResults';
  abstained: Scalars['Uint'];
  amount: Scalars['Uint'];
  count: Scalars['Uint'];
  managerId: Scalars['String'];
  managerName: Scalars['String'];
  prevAmount?: Maybe<Scalars['Uint']>;
  prevApproval?: Maybe<Scalars['Uint']>;
  res: Array<AggregatedSliderResult>;
};

export type AggregatedQuestionResults = {
  __typename?: 'AggregatedQuestionResults';
  abstained: Scalars['Uint'];
  amount: Scalars['Uint'];
  count: Scalars['Uint'];
  managerId: Scalars['String'];
  managerName: Scalars['String'];
  prevAmount?: Maybe<Scalars['Uint']>;
  prevApproval?: Maybe<Scalars['Uint']>;
  res?: Maybe<AggregatedResultsUnion>;
};

export type AggregatedResultsUnion = AggregatedSingleChoiceResults | AggregatedSliderResults;

export type AggregatedSingleChoiceResult = {
  __typename?: 'AggregatedSingleChoiceResult';
  answerAmount: Scalars['Uint'];
  answerId: Scalars['String'];
};

export type AggregatedSingleChoiceResults = {
  __typename?: 'AggregatedSingleChoiceResults';
  results: Array<AggregatedSingleChoiceResult>;
};

export type AggregatedSliderResult = {
  __typename?: 'AggregatedSliderResult';
  answerAmount: Scalars['Uint'];
  value: Scalars['SliderValue'];
};

export type AggregatedSliderResults = {
  __typename?: 'AggregatedSliderResults';
  results: Array<AggregatedSliderResult>;
};

export enum CanSeeResult {
  Error = 'ERROR',
  SurveyStillRunning = 'SurveyStillRunning',
  CanSee = 'canSee',
  NotManager = 'notManager'
}

export type EditSurveyIn = {
  duration?: InputMaybe<Scalars['Uint']>;
  startDays?: InputMaybe<Array<Scalars['DayMonth']>>;
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['String'];
  name: Scalars['String'];
  subordinates: Array<Employee>;
};

export type MultipleChoiceAnswer = {
  __typename?: 'MultipleChoiceAnswer';
  AnswerIDs: Array<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type MultipleChoiceResultIn = {
  ids: Array<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addResults: Array<Scalars['String']>;
  editSurvey: Scalars['Boolean'];
  newSurvey?: Maybe<Scalars['String']>;
  removeSurvey: Scalars['Boolean'];
  sendFeedback: Scalars['Boolean'];
};


export type MutationAddResultsArgs = {
  results: Array<ResultIn>;
  surveyID: Scalars['String'];
};


export type MutationEditSurveyArgs = {
  surveyData: EditSurveyIn;
  surveyID: Scalars['String'];
};


export type MutationNewSurveyArgs = {
  surveyData: SurveyIn;
};


export type MutationRemoveSurveyArgs = {
  surveyID: Scalars['String'];
};


export type MutationSendFeedbackArgs = {
  feedback: Scalars['String'];
};

export type Page = {
  __typename?: 'Page';
  aggregatedSliderResults: Array<AggregatedPageResults>;
  id: Scalars['String'];
  questions: Array<Question>;
};


export type PageAggregatedSliderResultsArgs = {
  count: Scalars['Uint'];
};

export type PageIn = {
  questions: Array<QuestionIn>;
};

export type Query = {
  __typename?: 'Query';
  canSeeResults: CanSeeResult;
  canTakeSurvey: SurveyAnswerStatus;
  getOrganisation: Employee;
  getResultsByQuestion: Array<Result>;
  getSurvey?: Maybe<Survey>;
};


export type QueryCanSeeResultsArgs = {
  surveyID: Scalars['String'];
};


export type QueryCanTakeSurveyArgs = {
  surveyID: Scalars['String'];
};


export type QueryGetOrganisationArgs = {
  rootEmployeeId: Scalars['String'];
};


export type QueryGetResultsByQuestionArgs = {
  count?: InputMaybe<Scalars['Uint']>;
  questionID: Scalars['String'];
  surveyID: Scalars['String'];
};


export type QueryGetSurveyArgs = {
  surveyID: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  aggregatedResults: Array<AggregatedQuestionResults>;
  answerOptions: Array<Scalars['String']>;
  id: Scalars['String'];
  qType: QuestionType;
};


export type QuestionAggregatedResultsArgs = {
  count: Scalars['Uint'];
};

export type QuestionIn = {
  answerOptions?: InputMaybe<Scalars['Uint']>;
  qType: QuestionType;
};

export enum QuestionType {
  MultipleChoice = 'multipleChoice',
  SingleChoice = 'singleChoice',
  Slider = 'slider',
  Text = 'text'
}

export type Result = {
  __typename?: 'Result';
  answer: ResultAnswer;
  count: Scalars['Uint'];
  id: Scalars['String'];
  managerID: Scalars['String'];
  managerName: Scalars['String'];
  questionID: Scalars['String'];
};

export type ResultAnswer = AbstainedAnswer | MultipleChoiceAnswer | SingleChoiceAnswer | SliderAnswer | TextAnswer;

export type ResultIn = {
  abstained?: InputMaybe<AbstainedResultIn>;
  multipleChoice?: InputMaybe<MultipleChoiceResultIn>;
  pageID: Scalars['String'];
  questionID: Scalars['String'];
  singleChoice?: InputMaybe<SingleChoiceResultIn>;
  slider?: InputMaybe<SliderResultIn>;
  text?: InputMaybe<TextResultIn>;
};

export type SingleChoiceAnswer = {
  __typename?: 'SingleChoiceAnswer';
  AnswerID: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
};

export type SingleChoiceResultIn = {
  id: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
};

export type SliderAnswer = {
  __typename?: 'SliderAnswer';
  remark?: Maybe<Scalars['String']>;
  slider: Scalars['SliderValue'];
};

export type SliderResultIn = {
  remark?: InputMaybe<Scalars['String']>;
  value: Scalars['SliderValue'];
};

export type Survey = {
  __typename?: 'Survey';
  closedAt?: Maybe<Scalars['UnixTime']>;
  createdAt: Scalars['UnixTime'];
  duration: Scalars['Uint'];
  firstStart: Scalars['UnixTime'];
  id: Scalars['String'];
  occurrenceCount: Scalars['Uint'];
  pages: Array<Page>;
  previousOccurrence?: Maybe<Scalars['Uint']>;
  startDays: Array<Scalars['DayMonth']>;
  startedAt: Scalars['UnixTime'];
  state: SurveyState;
};

export enum SurveyAnswerStatus {
  Error = 'ERROR',
  AlreadyTaken = 'alreadyTaken',
  CanTake = 'canTake',
  HasNoManager = 'hasNoManager',
  SurveyNotRunning = 'surveyNotRunning'
}

export type SurveyIn = {
  duration: Scalars['Uint'];
  firstStart: Scalars['UnixTime'];
  pages: Array<PageIn>;
  startDays: Array<Scalars['DayMonth']>;
};

export enum SurveyState {
  Closed = 'CLOSED',
  Created = 'CREATED',
  Creating = 'CREATING',
  Deleted = 'DELETED',
  Running = 'RUNNING'
}

export type TextAnswer = {
  __typename?: 'TextAnswer';
  text: Scalars['String'];
};

export type TextResultIn = {
  value: Scalars['String'];
};

export type SendFeedbackMutationVariables = Exact<{
  feedback: Scalars['String'];
}>;


export type SendFeedbackMutation = { __typename?: 'Mutation', sendFeedback: boolean };

export type AddResultsMutationVariables = Exact<{
  surveyID: Scalars['String'];
  results: Array<ResultIn> | ResultIn;
}>;


export type AddResultsMutation = { __typename?: 'Mutation', addResults: Array<string> };

export type CanTakeSurveyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CanTakeSurveyQuery = { __typename?: 'Query', canTakeSurvey: SurveyAnswerStatus };

export type CanSeeResultsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CanSeeResultsQuery = { __typename?: 'Query', canSeeResults: CanSeeResult };

export type GetSurveyCountQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSurveyCountQuery = { __typename?: 'Query', getSurvey?: { __typename?: 'Survey', occurrenceCount: number, previousOccurrence?: number | null } | null };

export type GetSurveyCreationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSurveyCreationQuery = { __typename?: 'Query', getSurvey?: { __typename?: 'Survey', createdAt: number } | null };

export type GetSurveyAndResultsQueryVariables = Exact<{
  id: Scalars['String'];
  count: Scalars['Uint'];
}>;


export type GetSurveyAndResultsQuery = { __typename?: 'Query', getSurvey?: { __typename?: 'Survey', id: string, createdAt: number, firstStart: number, startedAt: number, closedAt?: number | null, state: SurveyState, occurrenceCount: number, previousOccurrence?: number | null, startDays: Array<string>, duration: number, pages: Array<{ __typename?: 'Page', id: string, aggregatedSliderResults: Array<{ __typename?: 'AggregatedPageResults', count: number, prevApproval?: number | null, prevAmount?: number | null, amount: number, abstained: number, managerName: string, managerId: string, res: Array<{ __typename?: 'AggregatedSliderResult', answerAmount: number, value: 0 | 1 | 3 | 4 }> }>, questions: Array<{ __typename?: 'Question', id: string, qType: QuestionType, answerOptions: Array<string>, aggregatedResults: Array<{ __typename?: 'AggregatedQuestionResults', count: number, prevApproval?: number | null, prevAmount?: number | null, abstained: number, amount: number, managerName: string, managerId: string, res?: { __typename: 'AggregatedSingleChoiceResults', results: Array<{ __typename?: 'AggregatedSingleChoiceResult', answerAmount: number }> } | { __typename: 'AggregatedSliderResults', results: Array<{ __typename?: 'AggregatedSliderResult', value: 0 | 1 | 3 | 4, answerAmount: number }> } | null }> }> }> } | null };

export type GetSurveyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSurveyQuery = { __typename?: 'Query', getSurvey?: { __typename?: 'Survey', id: string, createdAt: number, firstStart: number, startedAt: number, closedAt?: number | null, state: SurveyState, occurrenceCount: number, previousOccurrence?: number | null, startDays: Array<string>, duration: number, pages: Array<{ __typename?: 'Page', id: string, questions: Array<{ __typename?: 'Question', id: string, qType: QuestionType, answerOptions: Array<string> }> }> } | null };

export type GetQuestionResultsQueryVariables = Exact<{
  surveyId: Scalars['String'];
  questionId: Scalars['String'];
  count?: InputMaybe<Scalars['Uint']>;
}>;


export type GetQuestionResultsQuery = { __typename?: 'Query', getResultsByQuestion: Array<{ __typename?: 'Result', id: string, managerID: string, managerName: string, count: number, answer: { __typename: 'AbstainedAnswer', remark?: string | null } | { __typename: 'MultipleChoiceAnswer', AnswerIDs: Array<string>, remark?: string | null } | { __typename: 'SingleChoiceAnswer', AnswerID: string, remark?: string | null } | { __typename: 'SliderAnswer', slider: 0 | 1 | 3 | 4, remark?: string | null } | { __typename: 'TextAnswer', text: string } }> };

export type GetOrganisationQueryVariables = Exact<{
  rootID: Scalars['String'];
}>;


export type GetOrganisationQuery = { __typename?: 'Query', getOrganisation: { __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string }> }> }> }> }> }> }> }> }> }> } };

export type EmployeeFragmentFragment = { __typename?: 'Employee', id: string, name: string };

export type EmployeeFragmentRecFragment = { __typename?: 'Employee', subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string, subordinates: Array<{ __typename?: 'Employee', id: string, name: string }> }> }> }> }> }> }> }> }> }> };

export const EmployeeFragmentFragmentDoc = gql`
    fragment employeeFragment on Employee {
  id
  name
}
    `;
export const EmployeeFragmentRecFragmentDoc = gql`
    fragment employeeFragmentRec on Employee {
  subordinates {
    ...employeeFragment
    subordinates {
      ...employeeFragment
      subordinates {
        ...employeeFragment
        subordinates {
          ...employeeFragment
          subordinates {
            ...employeeFragment
            subordinates {
              ...employeeFragment
              subordinates {
                ...employeeFragment
                subordinates {
                  ...employeeFragment
                  subordinates {
                    ...employeeFragment
                    subordinates {
                      ...employeeFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${EmployeeFragmentFragmentDoc}`;
export const SendFeedbackDocument = gql`
    mutation sendFeedback($feedback: String!) {
  sendFeedback(feedback: $feedback)
}
    `;
export type SendFeedbackMutationFn = Apollo.MutationFunction<SendFeedbackMutation, SendFeedbackMutationVariables>;

/**
 * __useSendFeedbackMutation__
 *
 * To run a mutation, you first call `useSendFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackMutation, { data, loading, error }] = useSendFeedbackMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSendFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SendFeedbackMutation, SendFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFeedbackMutation, SendFeedbackMutationVariables>(SendFeedbackDocument, options);
      }
export type SendFeedbackMutationHookResult = ReturnType<typeof useSendFeedbackMutation>;
export type SendFeedbackMutationResult = Apollo.MutationResult<SendFeedbackMutation>;
export type SendFeedbackMutationOptions = Apollo.BaseMutationOptions<SendFeedbackMutation, SendFeedbackMutationVariables>;
export const AddResultsDocument = gql`
    mutation addResults($surveyID: String!, $results: [ResultIn!]!) {
  addResults(surveyID: $surveyID, results: $results)
}
    `;
export type AddResultsMutationFn = Apollo.MutationFunction<AddResultsMutation, AddResultsMutationVariables>;

/**
 * __useAddResultsMutation__
 *
 * To run a mutation, you first call `useAddResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addResultsMutation, { data, loading, error }] = useAddResultsMutation({
 *   variables: {
 *      surveyID: // value for 'surveyID'
 *      results: // value for 'results'
 *   },
 * });
 */
export function useAddResultsMutation(baseOptions?: Apollo.MutationHookOptions<AddResultsMutation, AddResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddResultsMutation, AddResultsMutationVariables>(AddResultsDocument, options);
      }
export type AddResultsMutationHookResult = ReturnType<typeof useAddResultsMutation>;
export type AddResultsMutationResult = Apollo.MutationResult<AddResultsMutation>;
export type AddResultsMutationOptions = Apollo.BaseMutationOptions<AddResultsMutation, AddResultsMutationVariables>;
export const CanTakeSurveyDocument = gql`
    query canTakeSurvey($id: String!) {
  canTakeSurvey(surveyID: $id)
}
    `;

/**
 * __useCanTakeSurveyQuery__
 *
 * To run a query within a React component, call `useCanTakeSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanTakeSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanTakeSurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCanTakeSurveyQuery(baseOptions: Apollo.QueryHookOptions<CanTakeSurveyQuery, CanTakeSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanTakeSurveyQuery, CanTakeSurveyQueryVariables>(CanTakeSurveyDocument, options);
      }
export function useCanTakeSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanTakeSurveyQuery, CanTakeSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanTakeSurveyQuery, CanTakeSurveyQueryVariables>(CanTakeSurveyDocument, options);
        }
export type CanTakeSurveyQueryHookResult = ReturnType<typeof useCanTakeSurveyQuery>;
export type CanTakeSurveyLazyQueryHookResult = ReturnType<typeof useCanTakeSurveyLazyQuery>;
export type CanTakeSurveyQueryResult = Apollo.QueryResult<CanTakeSurveyQuery, CanTakeSurveyQueryVariables>;
export const CanSeeResultsDocument = gql`
    query canSeeResults($id: String!) {
  canSeeResults(surveyID: $id)
}
    `;

/**
 * __useCanSeeResultsQuery__
 *
 * To run a query within a React component, call `useCanSeeResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanSeeResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanSeeResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCanSeeResultsQuery(baseOptions: Apollo.QueryHookOptions<CanSeeResultsQuery, CanSeeResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanSeeResultsQuery, CanSeeResultsQueryVariables>(CanSeeResultsDocument, options);
      }
export function useCanSeeResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanSeeResultsQuery, CanSeeResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanSeeResultsQuery, CanSeeResultsQueryVariables>(CanSeeResultsDocument, options);
        }
export type CanSeeResultsQueryHookResult = ReturnType<typeof useCanSeeResultsQuery>;
export type CanSeeResultsLazyQueryHookResult = ReturnType<typeof useCanSeeResultsLazyQuery>;
export type CanSeeResultsQueryResult = Apollo.QueryResult<CanSeeResultsQuery, CanSeeResultsQueryVariables>;
export const GetSurveyCountDocument = gql`
    query getSurveyCount($id: String!) {
  getSurvey(surveyID: $id) {
    occurrenceCount
    previousOccurrence
  }
}
    `;

/**
 * __useGetSurveyCountQuery__
 *
 * To run a query within a React component, call `useGetSurveyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyCountQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyCountQuery, GetSurveyCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyCountQuery, GetSurveyCountQueryVariables>(GetSurveyCountDocument, options);
      }
export function useGetSurveyCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyCountQuery, GetSurveyCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyCountQuery, GetSurveyCountQueryVariables>(GetSurveyCountDocument, options);
        }
export type GetSurveyCountQueryHookResult = ReturnType<typeof useGetSurveyCountQuery>;
export type GetSurveyCountLazyQueryHookResult = ReturnType<typeof useGetSurveyCountLazyQuery>;
export type GetSurveyCountQueryResult = Apollo.QueryResult<GetSurveyCountQuery, GetSurveyCountQueryVariables>;
export const GetSurveyCreationDocument = gql`
    query getSurveyCreation($id: String!) {
  getSurvey(surveyID: $id) {
    createdAt
  }
}
    `;

/**
 * __useGetSurveyCreationQuery__
 *
 * To run a query within a React component, call `useGetSurveyCreationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyCreationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyCreationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyCreationQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyCreationQuery, GetSurveyCreationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyCreationQuery, GetSurveyCreationQueryVariables>(GetSurveyCreationDocument, options);
      }
export function useGetSurveyCreationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyCreationQuery, GetSurveyCreationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyCreationQuery, GetSurveyCreationQueryVariables>(GetSurveyCreationDocument, options);
        }
export type GetSurveyCreationQueryHookResult = ReturnType<typeof useGetSurveyCreationQuery>;
export type GetSurveyCreationLazyQueryHookResult = ReturnType<typeof useGetSurveyCreationLazyQuery>;
export type GetSurveyCreationQueryResult = Apollo.QueryResult<GetSurveyCreationQuery, GetSurveyCreationQueryVariables>;
export const GetSurveyAndResultsDocument = gql`
    query getSurveyAndResults($id: String!, $count: Uint!) {
  getSurvey(surveyID: $id) {
    id
    createdAt
    firstStart
    startedAt
    closedAt
    state
    occurrenceCount
    previousOccurrence
    startDays
    duration
    pages {
      id
      aggregatedSliderResults(count: $count) {
        count
        res {
          answerAmount
          value
        }
        prevApproval
        prevAmount
        amount
        abstained
        managerName
        managerId
      }
      questions {
        id
        qType
        aggregatedResults(count: $count) {
          count
          prevApproval
          prevAmount
          res {
            __typename
            ... on AggregatedSliderResults {
              __typename
              results {
                value
                answerAmount
              }
            }
            ... on AggregatedSingleChoiceResults {
              __typename
              results {
                answerAmount
              }
            }
          }
          abstained
          amount
          managerName
          managerId
        }
        answerOptions
      }
    }
  }
}
    `;

/**
 * __useGetSurveyAndResultsQuery__
 *
 * To run a query within a React component, call `useGetSurveyAndResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyAndResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyAndResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetSurveyAndResultsQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyAndResultsQuery, GetSurveyAndResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyAndResultsQuery, GetSurveyAndResultsQueryVariables>(GetSurveyAndResultsDocument, options);
      }
export function useGetSurveyAndResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyAndResultsQuery, GetSurveyAndResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyAndResultsQuery, GetSurveyAndResultsQueryVariables>(GetSurveyAndResultsDocument, options);
        }
export type GetSurveyAndResultsQueryHookResult = ReturnType<typeof useGetSurveyAndResultsQuery>;
export type GetSurveyAndResultsLazyQueryHookResult = ReturnType<typeof useGetSurveyAndResultsLazyQuery>;
export type GetSurveyAndResultsQueryResult = Apollo.QueryResult<GetSurveyAndResultsQuery, GetSurveyAndResultsQueryVariables>;
export const GetSurveyDocument = gql`
    query getSurvey($id: String!) {
  getSurvey(surveyID: $id) {
    id
    createdAt
    firstStart
    startedAt
    closedAt
    state
    occurrenceCount
    previousOccurrence
    startDays
    duration
    pages {
      id
      questions {
        id
        qType
        answerOptions
      }
    }
  }
}
    `;

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
      }
export function useGetSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
        }
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>;
export type GetSurveyLazyQueryHookResult = ReturnType<typeof useGetSurveyLazyQuery>;
export type GetSurveyQueryResult = Apollo.QueryResult<GetSurveyQuery, GetSurveyQueryVariables>;
export const GetQuestionResultsDocument = gql`
    query getQuestionResults($surveyId: String!, $questionId: String!, $count: Uint) {
  getResultsByQuestion(
    surveyID: $surveyId
    questionID: $questionId
    count: $count
  ) {
    id
    managerID
    managerName
    count
    answer {
      ... on AbstainedAnswer {
        __typename
        remark
      }
      ... on SingleChoiceAnswer {
        __typename
        AnswerID
        remark
      }
      ... on MultipleChoiceAnswer {
        __typename
        AnswerIDs
        remark
      }
      ... on TextAnswer {
        __typename
        text
      }
      ... on SliderAnswer {
        __typename
        slider
        remark
      }
    }
  }
}
    `;

/**
 * __useGetQuestionResultsQuery__
 *
 * To run a query within a React component, call `useGetQuestionResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionResultsQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      questionId: // value for 'questionId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetQuestionResultsQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionResultsQuery, GetQuestionResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionResultsQuery, GetQuestionResultsQueryVariables>(GetQuestionResultsDocument, options);
      }
export function useGetQuestionResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionResultsQuery, GetQuestionResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionResultsQuery, GetQuestionResultsQueryVariables>(GetQuestionResultsDocument, options);
        }
export type GetQuestionResultsQueryHookResult = ReturnType<typeof useGetQuestionResultsQuery>;
export type GetQuestionResultsLazyQueryHookResult = ReturnType<typeof useGetQuestionResultsLazyQuery>;
export type GetQuestionResultsQueryResult = Apollo.QueryResult<GetQuestionResultsQuery, GetQuestionResultsQueryVariables>;
export const GetOrganisationDocument = gql`
    query getOrganisation($rootID: String!) {
  getOrganisation(rootEmployeeId: $rootID) {
    id
    name
    ...employeeFragmentRec
  }
}
    ${EmployeeFragmentRecFragmentDoc}`;

/**
 * __useGetOrganisationQuery__
 *
 * To run a query within a React component, call `useGetOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationQuery({
 *   variables: {
 *      rootID: // value for 'rootID'
 *   },
 * });
 */
export function useGetOrganisationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
      }
export function useGetOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
        }
export type GetOrganisationQueryHookResult = ReturnType<typeof useGetOrganisationQuery>;
export type GetOrganisationLazyQueryHookResult = ReturnType<typeof useGetOrganisationLazyQuery>;
export type GetOrganisationQueryResult = Apollo.QueryResult<GetOrganisationQuery, GetOrganisationQueryVariables>;